import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor, orderBy, SortDescriptor, State } from '@progress/kendo-data-query';
import { dataSqlIcon } from '@progress/kendo-svg-icons';
import { filtersOld, saveSelectedCriteria } from 'src/app/features/prospect/interfaces/criteria.model';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { ActivatedRoute } from '@angular/router';
import { CustomNotifireService } from 'src/app/shared/services/view-prospect-notifire.service';
import { SelectProspectsComponent } from '../select-prospects/select-prospects.component';
import { CreateListScreen } from '../../../create-list-screen';
import { CreateListService } from '../../../services/CreateList.service';
import { DataBindingDirective, PageChangeEvent } from '@progress/kendo-angular-grid';
import { type } from 'os';
import { process } from '@progress/kendo-data-query';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { PaginatorModel } from 'src/app/shared/class/paginatorModel';
import { AddprospectService } from 'src/app/features/prospect/services/addprospect.service';
import { ListDataService } from 'src/app/shared/services/list-data.service';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { DatePipe } from '@angular/common';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { SignalRNotifierService } from 'src/app/features/admin/services/Notifier Services/signalR-notifier.service';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
@Component({
  selector: 'app-create-query',
  templateUrl: './create-query.component.html',
  styleUrls: ['./create-query.component.css']
})
export class CreateQueryComponent implements OnInit {
  @ViewChild('templateForState', { static: true })
  public templateForState !: TemplateRef<any>;
  @ViewChild('template2', { static: true })
  public template2 !: TemplateRef<any>;
  @ViewChild('templateForContact', { static: true })
  public templateForContact !: TemplateRef<any>;
  @ViewChild('filterDiv') filterDiv!: ElementRef<HTMLInputElement>;
  @ViewChild('templateForNumber', { static: true })
  public templateForNumber!: TemplateRef<any>;
  @ViewChild('templateForNumberEXP', { static: true })
  public templateForNumberEXP!: TemplateRef<any>;
  @ViewChild('templateForPolicy', { static: true })
  public templateForPolicy !: TemplateRef<any>;
  @ViewChild('templateForClientStatus', { static: true })
  public templateForClientStatus!: TemplateRef<any>;
  @ViewChild('templateForRecallStatus', { static: true })
  public templateForRecallStatus!: TemplateRef<any>;
  @ViewChild('templateForWbaStatus', { static: true })
  public templateForWbaStatus!: TemplateRef<any>;
  @ViewChild('templateForWbaBussinessStatus', { static: true })
  public templateForWbaBussinessStatus!: TemplateRef<any>;
  @ViewChild('templateForPrimaryIndustry', { static: true })
  public templateForPrimaryIndustry!: TemplateRef<any>;
  @ViewChild('templateForSecondaryIndustry', { static: true })
  public templateForSecondaryIndustry!: TemplateRef<any>;
  @ViewChild('templateForProgress', { static: true })
  public templateForProgress !: TemplateRef<any>;
  @ViewChild('templateForResult', { static: true })
  public templateForResult!: TemplateRef<any>;
  @ViewChild('templateprofitCenter', { static: true })
  public templateprofitCenter !: TemplateRef<any>;
  @ViewChild('templateForUserName', { static: true })
  public templateForUserName !: TemplateRef<any>;
  @ViewChild('templateForNumberWithoutComma', { static: true })
  public templateForNumberWithoutComma!: TemplateRef<any>;
  public state: State = {
    skip: 0,
    take: 5
  };

  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  //public SelectProspectsComponent: SelectProspectsComponent;
  public ListID: number = 0;
  showError: boolean = false;
  errorMessage: any;
  data1: any = [];
  data2: any = [];
  selectedListId: number = 0;
  public sizes = [10, 25, 50];
  selectedParPageOption: number = 0;
  public gridFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
  public sort: SortDescriptor[] = [
    {
      field: 'prospectName',
      dir: 'asc',
    },
  ];
  _paginator: PaginatorModel;
  public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public finlizedOutputfilter: saveSelectedCriteria = new saveSelectedCriteria();
  dateAfterTwoMnths: Date = new Date();
  pageNo: number = 1;
  public pageSize = 10;
  public totalCount = 0;
  isChecked: boolean = false
  searchGrid: string = '';
  pageTitle: string = 'Create List';
  filterForLob: any[] = [];
  filterValueArrayState: any[] = [];
  filterValueArrayLob: any[] = [];
  hideShowGrid: boolean = false;
  filterMsg: any;
  _createListScreen: CreateListScreen;
  selectedId: string = '0';
  //public filters: FilterExpression[] = [];
  blankArray: any[] = [];
  actionPerform = "ApplyFilter"
  public filters: FilterExpression[] = [
    {
      "field": "ProspectName",
      "title": "Company Name",
      "editor": "string"
    }
  ];
  _confirmBox!: ConfirmBox;
  _confirmBoxForExceedList!: ConfirmBox;
  _userMessages!: UserMessages;
  errorList: any = [];
  isSubmitted: boolean = false;
  _navigationPath: NavigationPath;
  invalidFilter: boolean = false;

  createQueryForm: UntypedFormGroup;
  showSaveListMsg: boolean = false;
  saveType: any = '';
  minimumDate: Date;
  gridFilterArrayClientStatus: any[] = [];


  prospectList: any;
  public multiple = false;
  public allowUnsort = true;
  masterProspectList: any[] = [];
  FinalProspectList: any = []
  totalRecord: any;
  gridData: any[] = [];
  public skip = 0;
  public buttonCount = 5;
  showExpirationCalendar: boolean = false;
  disableExpirationCalendar: boolean = false;
  filteredDataProspectsIds: any[] = [];
  ListId: any;
  ListDetailData: any = {};
  queryDetail: any;
  getManageListId: any;
  selectedDefaultList: any;
  isFilter: boolean = true;
  gridFilters: any;
  $searchingText = new Subject<void>();
  templateList: any;
  showSaveButton: boolean = false;
  templateId: any;
  showListChangeWarning: boolean = false;
  isFilter1: boolean = false;
  whitespace: boolean = false;
  unAthorizesd: any = '';
  statesList: any;
  isDataAvailable: boolean = false;
  selectedLobFilterValue: any;
  selectedStateFilterValue: any;
  selectedItem: any;
  currentSelectedField: any;
  filterValueForState: any;
  filterValueForLob: any;
  selectedContactStateFilterValue: any;
  filterValueArraycontactState: any[] = [];
  filterValueForContact: any;
  controlId: any;
  speciaPolicyType: any;
  clientStatus: any;
  recallForStatusList: any;
  wbaForStatusList: any;
  businessStatusList: any
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  gridFilterArrayState: any[] = [];
  gridFilterArrayIndustry: any[] = [];
  filterValueCopy: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  prospectIndustryList: any;
  gridFilterForIndustry: any;
  policyTypeList: any[] = [];
  businessLine: any[] = [];
  progress: any[] = [];
  resultListForGrid: any;
  EpicStatusFilterForResult: any;
  filterBlock: boolean = true;
  profitCenterData: any;
  excludedColumns: any;
  nextButtonVisiable: boolean = false;
  listTypeName: any;
  gridLastContactedByListArray: any[] = []
  userList: any;

  constructor(private renderer: Renderer2,
    private _saveJsonService: CreateListService,
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private _router: Router,
    private _listDataService: ListDataService,
    private _AddprospectService: AddprospectService,
    private _ProspectListService: ProspectListService,
    private datepipe: DatePipe,
    private modalService: NgbModal,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _preventBack: PreventBackButtonService,
    public profileInfoService: ProfileInfoService,
    private signalRNotifire: SignalRNotifierService,
    private _addWBAService: WhiteBoardActivityService,

    private ref: ChangeDetectorRef
  ) {
    this.dateAfterTwoMnths.setDate((new Date).getDate() + 60);
    this.minimumDate = new Date();
    this.minimumDate.setDate(this.minimumDate.getDate() + 1);
    sessionStorage.removeItem('editMode');

    this._createListScreen = new CreateListScreen();
    this._confirmBox = new ConfirmBox();
    this._confirmBoxForExceedList = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this._paginator = new PaginatorModel();
    this.$searchingText.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.getProspectList(this.selectedListId);
    });

    this.createQueryForm = this.fb.group({
      listName: ['', [Validators.required, Validators.maxLength(100)]],
      listType: ['Static', [Validators.required]],
      template: [''],
      expirationDate: ['']

    })
  }


  ngOnInit(): void {
    this.controlId = this.newGuid();
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    })
    this._preventBack.preventBackButton();
    this.getProspectListDetails();
    //checking form is opened in edit mode
    this.showListChangeWarning = this._router.url.includes('edit-list')
    if (this.showListChangeWarning) {
      sessionStorage.setItem('editMode', 'edit');
    }
    this.ListId = sessionStorage.getItem('manageListId');
    //for list create
    this.getTemplates()
    if (this.ListId == 0) {
      this.createQueryForm.get('expirationDate')?.setValue(this.dateAfterTwoMnths);
      //this.getProspectList(0)
    }
    this.getUserList();
  }

  getProspectListDetails() {
    var getProspectFilterJSON = this._ProspectListService.getProspectFilterJSON(); // to get Filter json list
    var getProspectSates = this._AddprospectService.getProspectStates(); // to get state list
    var getProspectIndustryList = this._AddprospectService.getProspectIndustry(); // to get industry list
    var getBusinessStatusList = this._addWBAService.getBusinessStatusList(); //to get WBA.MasterBusinessStatus list
    var getBusinessLineList = this._addWBAService.getBusinessLineList();    //to get Business Line list
    var getPolicyTypeList = this._addWBAService.getPolicyTypeList(5);   //to get policy Type list for speciality
    var getProgressList = this._addWBAService.getprogressList();                                                               //to get Progress list
    forkJoin([getProspectFilterJSON, getProspectSates, getProspectIndustryList, getBusinessStatusList,
      getBusinessLineList, getPolicyTypeList, getProgressList]).subscribe(resp => {
        this.filters = resp[0].masterFilterOptionList;
        this.statesList = resp[1].statesList
        this.prospectIndustryList = resp[2].prospectIndustryList;
        this.businessStatusList = resp[3].businessStatusList;
        this.businessLine = resp[4].businessLineList;
        this.policyTypeList = resp[5].businessLineList;
        this.progress = resp[6].progressList;
        //for list edit
        if (this.ListId > 0) {
          //this.createQueryForm.controls['listType'].disable();
          this.pageTitle = 'Edit List';
          sessionStorage.setItem('editMode', 'edit');
          this.getQueryById(this.ListId)
        }
        this.getProspectFilterJSON();
      })
  }

  //to get Progress list
  // getProgressList() {
  //   this._addWBAService.getprogressList().subscribe(resp => {
  //     this.progress = resp.progressList;
  //   })
  // }
  getBusinessStatusList(): void {
    this._addWBAService.getBusinessStatusList().subscribe((response: any) => {
      this.businessStatusList = response.businessStatusList
    });
  }
  getProspectIndustry(): void {
    this._AddprospectService.getProspectIndustry().subscribe((response: any) => {
      this.prospectIndustryList = response.prospectIndustryList;
    });
  }

  //to get policy Type list for speciality
  getPolicyTypeList() {
    this._addWBAService.getPolicyTypeList(5).subscribe(resp => {
      this.policyTypeList = resp.businessLineList
    })
  }


  //to get Business Line list
  getBusinessLineList() {
    this._addWBAService.getBusinessLineList().subscribe(resp => {
      this.businessLine = resp.businessLineList;
    })
  }

  public getProspectFilterJSON(): void {
    // this._ProspectListService.getProspectFilterJSON().subscribe((response: any) => {
    //   this.filters = response.masterFilterOptionList;
    if (this.filters) {
      this.clientStatus = ['Not a client', 'Client', 'Lost Client'];
      this.recallForStatusList = ['Open', 'Completed', 'Expired'];
      this.wbaForStatusList = ['Open', 'Won', 'Lost'];
      this.resultListForGrid = ['Bound', 'Not Quoted,Not Bound', 'Not Renewed', 'Quoted,Not Bound', 'Open'];
      this.profitCenterData = ['Lamb', 'CREIS', 'TruePartners', 'TruePartners - Wheels']
      this.filters.forEach((element: any) => {
        if (element.field == 'State') {
          element.operators = ["eq"];
        }
        else if (
          element.field == 'ProspectInformation.NoOfEmployees' ||
          element.field == 'ProspectInformation.Revenue' ||
          element.field == 'ProspectInformation.PackagePremium' ||
          element.field == 'ProspectInformation.WorkersCompensationPremium' ||
          element.field == 'WBA.TargetPremium' ||
          element.field == 'WBAOutComeDetail.BoundRevenue' ||
          element.field == 'Prospect.TotalRevenue' ||
          element.field == 'Prospect.DispositionCount'
        ) {
          element.editorTemplate = this.templateForNumber;
        }
        else if (
          element.field == 'ProspectInformation.EXPMOD'
        ) {
          element.editorTemplate = this.templateForNumberEXP;
        }
        else if (
          element.field == 'Prospect.Id'
        ) {
          element.editorTemplate = this.templateForNumberWithoutComma;
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'WBALine.Line') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'MasterPolicyType.line') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'pmv_view.TagName' || element.field == 'Tag.Name') {
          element.operators = ["contains", "eq", "neq", "doesnotcontain", "isnull"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'Contact.Office1State') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'prospectstatus.Status') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'ProspectRecall.Status') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'WBAStatusId') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'WBA.MasterBusinessStatus') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'ProspectIndustry.IndustryName') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'PI.IndustryName') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'industryclassandcodeinformation.Class.NTEE') {
          element.operators = ["contains", "eq", "neq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'industryclassandcodeinformation.Class.PCS') {
          element.operators = ["contains", "eq", "neq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'industryclassandcodeinformation.Class.SIC') {
          element.operators = ["contains", "eq", "neq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'industryclassandcodeinformation.Class.NAICS') {
          element.operators = ["contains", "eq", "neq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'ProspectProgress.ProgressName') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'ProspectEpicStatus.EpicStatusName') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'ProfitCenter') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'Prospect.CreatedBy') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'WBA.CreatedBy') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'Prospect.LastContactedBy') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'Prospect.ModifiedBy') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'WBA.MarketingManagerId') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'WBA.OwnerUserName') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'wba.OriginalProducerName') {
          element.operators = ["eq"];
        }
      });
      let getStateIndex = this.filters.findIndex((x: any) => x.field == 'State')!
      let getLobIndex = this.filters.findIndex((x: any) => x.field == 'WBALine.Line')!
      this.filters[getStateIndex].editorTemplate = this.templateForState;
      this.filters[getLobIndex].editorTemplate = this.template2;
      let getContactStateIndex = this.filters.findIndex((x: any) => x.field == 'Contact.Office1State')!
      this.filters[getContactStateIndex].editorTemplate = this.templateForContact;
      let getspecialPolicyIndex = this.filters.findIndex((x: any) => x.field == 'MasterPolicyType.line')!
      this.filters[getspecialPolicyIndex].editorTemplate = this.templateForPolicy;
      this.filterValueArrayState = this.selectedStateFilterValue;
      let getspecialClientStatus = this.filters.findIndex((x: any) => x.field == 'prospectstatus.Status')!
      this.filters[getspecialClientStatus].editorTemplate = this.templateForClientStatus;
      let getRecallStatus = this.filters.findIndex((x: any) => x.field == 'ProspectRecall.Status')!
      this.filters[getRecallStatus].editorTemplate = this.templateForRecallStatus;
      let getWbaStatus = this.filters.findIndex((x: any) => x.field == 'WBAStatusId')!
      this.filters[getWbaStatus].editorTemplate = this.templateForWbaStatus;
      let getWbaBussinessStatus = this.filters.findIndex((x: any) => x.field == 'WBA.MasterBusinessStatus')!
      this.filters[getWbaBussinessStatus].editorTemplate = this.templateForWbaBussinessStatus;
      let getPrimaryIndustry = this.filters.findIndex((x: any) => x.field == 'ProspectIndustry.IndustryName')!
      this.filters[getPrimaryIndustry].editorTemplate = this.templateForPrimaryIndustry;
      let getSecondaryIndustry = this.filters.findIndex((x: any) => x.field == 'PI.IndustryName')!
      this.filters[getSecondaryIndustry].editorTemplate = this.templateForSecondaryIndustry;
      let getspecialProgressIndex = this.filters.findIndex((x: any) => x.field == 'ProspectProgress.ProgressName')!
      this.filters[getspecialProgressIndex].editorTemplate = this.templateForProgress;
      let getspecialResultIndex = this.filters.findIndex((x: any) => x.field == 'ProspectEpicStatus.EpicStatusName')!
      this.filters[getspecialResultIndex].editorTemplate = this.templateForResult;
      let getprofitCenterIndex = this.filters.findIndex((x: any) => x.field == 'ProfitCenter')!
      this.filters[getprofitCenterIndex].editorTemplate = this.templateprofitCenter;
      let getWBACreatedBy = this.filters.findIndex((x: any) => x.field == 'WBA.CreatedBy')!
      this.filters[getWBACreatedBy].editorTemplate = this.templateForUserName;
      let getProspectCreatedBy = this.filters.findIndex((x: any) => x.field == 'Prospect.CreatedBy')!
      this.filters[getProspectCreatedBy].editorTemplate = this.templateForUserName;
      let getProspectLastContactedBy = this.filters.findIndex((x: any) => x.field == 'Prospect.LastContactedBy')!
      this.filters[getProspectLastContactedBy].editorTemplate = this.templateForUserName;
      let getProspectLastModifiedBy = this.filters.findIndex((x: any) => x.field == 'Prospect.ModifiedBy')!
      this.filters[getProspectLastModifiedBy].editorTemplate = this.templateForUserName;

      let getWBAMarketingManagerId = this.filters.findIndex((x: any) => x.field == 'WBA.MarketingManagerId')!
      this.filters[getWBAMarketingManagerId].editorTemplate = this.templateForUserName;
      let getownerUserName = this.filters.findIndex((x: any) => x.field == "WBA.OwnerUserName")!
      this.filters[getownerUserName].editorTemplate = this.templateForUserName;
      let getWbaOriginalProducerName = this.filters.findIndex((x: any) => x.field == "wba.OriginalProducerName")!
      this.filters[getWbaOriginalProducerName].editorTemplate = this.templateForUserName;
      // this.setDataUsingFilterStorage()

    }
    this.createQueryForm.get('template')?.disable();
  }

  checkBlankSpace(event: any) {
    if (event.target.value.trim().length == 0) {
      this.whitespace = false;
    }
  }

  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

  getProspectStates(): void {
    this._AddprospectService.getProspectStates().subscribe((response: any) => {
      this.statesList = response.statesList

    });
  }

  public onFilterChange(value: any): void {
    // var stateIndex = this.filterValue.filters.findIndex((x: any) => x.field == "State")
    // this.filterValue = value
    // if (stateIndex = -1){
    //   this.filterValue.filters.splice(stateIndex, 1)
    //   localStorage.removeItem('prospectFilterStorage');
    // }else{

    // }
    // if (value.filters[0].field == 'country' && (this.currentSelectedField == 'state' || this.currentSelectedField == '')) {
    //   this.fieldValue = ['US', 'UK', 'India', 'China'];
    //   this.currentSelectedField = 'country';
    // }
    // else if (value.filters[1].field == 'state' && this.currentSelectedField != 'state') {
    //   this.fieldValue = ['Uttar Pradesh', 'Haryana', 'Rajasthan', 'New Delhi'];
    //   this.currentSelectedField = 'state';
    // }
  }

  public editorValueChange(
    value: any,
    currentItem: FilterDescriptor,
    filterValue: CompositeFilterDescriptor
  ): void {


    currentItem.value = value;
    if (currentItem.field == 'State') {
      this.selectedItem = value
      this.currentSelectedField = 'State';
      // this.onCountrySelected(value);                   //To bind country with state
    } else if (currentItem.field == 'WBALine.Line') {
      //this.selectedItemForLOB = value
      this.currentSelectedField = 'WBALine.Line';
      // this.onCountrySelected(value);                   //To bind country with state
    }
  }

  //Line of business select dropdown
  public onChangeForLob(event: any) {
    //state is not in filter
    if (event.length == 0) {
      var lobIndex = this.filterValue.filters.findIndex((x: any) => x.field == "WBALine.Line")
      if (lobIndex > -1)
        this.filterValue.filters.splice(lobIndex, 1)
    }
    //state find in filter
    if (this.filterValue.filters.length > 0 || event !== null || event !== undefined) {
      var lobIndex = this.filterValue.filters.findIndex((x: any) => x.field == "WBALine.Line")
      if (lobIndex > -1)
        this.filterValue.filters.splice(lobIndex, 1)
      this.selectedLobFilterValue = event.toString()

    }
  }

  //State select dropdown
  public onChangeForState(event: any) {
    //state is not in filter
    if (event.length == 0) {
      var stateIndex = this.filterValue.filters.findIndex((x: any) => x.field == "State")
      if (stateIndex > -1)
        this.filterValue.filters.splice(stateIndex, 1)
    }
    //state find in filter
    if (this.filterValue.filters.length > 0 || event !== null || event !== undefined) {
      var stateIndex = this.filterValue.filters.findIndex((x: any) => x.field == "State")
      if (stateIndex > -1)
        this.filterValue.filters.splice(stateIndex, 1)
      this.selectedStateFilterValue = event.toString()

    }
  }

  //contact State select dropdown
  public onChangeForContactState(event: any) {
    this.selectedContactStateFilterValue = event.toString()
  }


  public onChange(event: any) {
    this.actionPerform = "FilterChange";
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    this.gridFilterArrayState = event;
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "state")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "state",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "state",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
    this.$searchingText.next();
  }
  //multi select for Industry
  public onChangeForIndustry(event: any) {
    this.actionPerform = "FilterChange";
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "ProspectIndustry.IndustryName")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "ProspectIndustry.IndustryName",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "ProspectIndustry.IndustryName",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = 0;
    this.$searchingText.next();
  }


  onTemplateChange(event: any) {
    this.templateId = event.target.value.split(': ').pop();;
    if (this.templateId == "") {
      //  this.templateId = 0;
      this.listTypeName = 'Static'
      this.createQueryForm.controls['listType'].patchValue('Static');
      this.createQueryForm.controls['listName'].patchValue('');
      this.filterValue = { logic: 'and', filters: [] };
      this.filterValueCopy = { logic: 'and', filters: [] };
      this.filterValue.filters.length > 0 ? JSON.stringify(this.filterValue) : ""
      this.filterValueCopy.filters.length > 0 ? JSON.stringify(this.filterValueCopy) : ""
      this.getProspectList(0);
    }

    if (this.templateId > 0) {
      this.getTemplateJSON(this.templateId)
    }
    // this.templateId = event.target.value.split(': ').pop();
    // if (this.templateId > 0) {
    //   //  this.templateId = event;
    //   this.showSaveButton = false;
    //   this.getTemplateJSON(this.templateId)
    // } else {
    //   this.templateId = 0;
    //   this.createQueryForm.controls['listType'].patchValue('Static');
    //   this.createQueryForm.controls['listName'].patchValue('');
    //   this.filterValue = { logic: 'and', filters: [] };
    //   this.filterValue.filters.length > 0 ? JSON.stringify(this.filterValue) : ""
    //   this.getProspectList(0);
    // }
  }

  onTemplateCheck(event: any) {
    if (event.target.checked == true) {
      this.createQueryForm.get('template')?.enable();
    } else {
      this.filterValue = { logic: 'and', filters: [] };
      this.filterValue.filters.length > 0 ? JSON.stringify(this.filterValue) : ""
      if (this.templateId > 0) {
        this.templateId = 0;
        this.getProspectList(0);
      }
      this.createQueryForm.get('template')?.disable();
      this.createQueryForm.get('template')?.setValue('');
    }
  }
  onlistTypeChange(type: string) {
    this.listTypeName = type;
    this.nextButtonVisiable = false;
    this.isDataAvailable = false;
    this.hideShowGrid = false;
    if (type == "Static") {
      this.disableExpirationCalendar = false;
      this.showSaveButton = false;
      this.createQueryForm.get('expirationDate')?.setValue(this.dateAfterTwoMnths);
    } else if (type == "Dynamic") {
      this.disableExpirationCalendar = true;
      this.showSaveButton = false;
      this.createQueryForm.get('expirationDate')?.setValue('');
    } else if (type == "System-Dafault") {
      this.showSaveButton = true;
      this.disableExpirationCalendar = true;
      this.createQueryForm.get('expirationDate')?.setValue('');

    }
    else {
      //template
      this.disableExpirationCalendar = true;
      this.showSaveButton = true;
      this.createQueryForm.get('expirationDate')?.setValue('');


    }
  }

  get f(): any {
    return this.createQueryForm?.controls;
  }

  //get list details By id
  getQueryById(id: any) {
    this._ProspectListService.getQueryById(id).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.listFilter;
          this.setFormData();
        }
      }),
      error: (() => {

      })
    })
  }
  //get Template details By id
  getTemplateJSON(id: any) {
    this._ProspectListService.getQueryById(id).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.listFilter;
          this.filterValue = JSON.parse(this.ListDetailData.filterJson)
          this.filterValueCopy = JSON.parse(this.ListDetailData.filterJson)
          this.convertContainsIntoEquals(this.filterValue);
          this.getProspectList(id)
        }
      }),
      error: (() => {

      })
    })
  }
  setFormData() {
    this.invalidFilter = false
    let listType = 'Static'
    if (this.ListDetailData.listType == 'QUERY' && this.ListDetailData.saveAs == 'Draft') {
      listType = 'Dynamic'
    }
    if (this.ListDetailData.listType == 'QUERY' && this.ListDetailData.saveAs == 'Published') {
      listType = 'Dynamic'
    }
    if (this.ListDetailData.listType == 'QUERY' && this.ListDetailData.saveAs == 'Template') {
      listType = 'Template'
      this.showSaveButton = true;
    }
    if (listType == "Static") {
      this.disableExpirationCalendar = false;
      this.createQueryForm.get('expirationDate')?.setValue(this.dateAfterTwoMnths);
    } else {
      this.disableExpirationCalendar = true;
      this.createQueryForm.get('expirationDate')?.setValue('');
    }
    this.listTypeName = listType;
    this.createQueryForm.controls['listType'].patchValue(listType);
    this.createQueryForm.controls['listName'].patchValue(this.ListDetailData.listName);
    //this.createListForm.controls['template'].patchValue(this.newMasterFilterList[1].template.id);
    if (this.ListDetailData.expirationDate != null) {
      this.createQueryForm.controls['expirationDate'].patchValue(new Date(this.ListDetailData.expirationDate));
    }
    // this.createQueryForm.controls['saveAs'].patchValue(this.ListDetailData.saveAs);
    if (this.ListDetailData.filterJson) {
      this.filterValue = JSON.parse(this.ListDetailData.filterJson)
      this.filterValueCopy = JSON.parse(this.ListDetailData.filterJson)
      this.convertContainsIntoEquals(this.filterValue);
      this.convertFormattedDateIntoDate(this.filterValue)
      this.selectedStateFilterValue = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "State")[0]?.value;
      this.selectedLobFilterValue = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "WBALine.Line")[0]?.value;
      this.filterValueForState = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "State")[0]?.value;
      this.filterValueArrayState = this.filterValueForState?.split(',')
      this.filterValueForLob = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "WBALine.Line")[0]?.value
      this.filterValueArrayLob = this.filterValueForLob?.split(',');
      this.filterValueForContact = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "ProspectContact.Office1State")[0]?.value
      this.filterValueArraycontactState = this.filterValueForContact?.split(',');
      this.gridFilterForIndustry = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "ProspectIndustry.IndustryName")[0]?.value;
      this.gridFilterArrayIndustry = this.gridFilterForIndustry?.split(',');
      this.EpicStatusFilterForResult = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "ProspectEpicStatus.EpicStatusName")[0]?.value;
      if (this.EpicStatusFilterForResult == "Null") {
        this.EpicStatusFilterForResult = "Open"
      }
      if (this.EpicStatusFilterForResult) {
        let commaValue1 = false, commaValue2 = false;
        if (this.EpicStatusFilterForResult.includes('Not Quoted,Not Bound')) {
          this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/Not Quoted,Not Bound/g, "");
          if (this.EpicStatusFilterForResult.includes(',,')) {
            this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/,,/g, ",");
          }
          commaValue1 = true;
        }
        if (this.EpicStatusFilterForResult.includes('Quoted,Not Bound')) {
          this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/Quoted,Not Bound/g, "");
          if (this.EpicStatusFilterForResult.includes(',,')) {
            this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/,,/g, ",");
          }
          commaValue2 = true;
        }
        this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/(\s*,?\s*)*$/, "");

        this.filterValue.filters.forEach((x: any) => {
          if (x.field == "ProspectEpicStatus.EpicStatusName") {
            x.value = this.EpicStatusFilterForResult?.split(',');
            if (commaValue1) {
              x.value.push("Not Quoted,Not Bound")
            }
            if (commaValue2) {
              x.value.push("Quoted,Not Bound")
            }
          }
        })

      }

    }
    //getting prosects by listId
    this.getProspectList(this.ListId)

  }
  getTemplates() {
    this._saveJsonService.getTemplates().subscribe((resp: any) => {
      this.templateList = resp.list;
    })
  }

  DateFormattingInAllGroups(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.DateFormattingInAllGroups(x)
        }
        else {
          let fieldType = this.filters.find((f: any) => f.field.toLowerCase() === x.field.toLowerCase())?.editor;
          if (fieldType) {
            if (fieldType?.toLowerCase() == 'date') {
              // var myDate = new Date(x.value).toISOString();
              x.value = this.datepipe.transform(x.value, 'yyyy-MM-dd');
            }
          }
        }
      })
    }
    return json;
  }
  changeDetector() {
    this.ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 2000);
  }

  public applyFilter(value: CompositeFilterDescriptor, val: boolean): void {
    this.filterBlock = false;
    this.ref.detectChanges();
    this.filterValueCopy = JSON.parse(JSON.stringify(value));
    this.filterValue = value;
    this.pageNo = 1;
    this.totalCount = 0;
    this.skip = 0;
    this.DateFormattingInAllGroups(this.filterValueCopy)
    this.getProspectList(this.selectedListId);
    this.isFilter1 = !val;
  }

  // public applyFilter(value: CompositeFilterDescriptor, val: boolean): void {
  //   this.actionPerform = 'ApplyFilter';
  //   this.filterValue = value;
  //   this.invalidFilter = false;
  //   this.totalCount = 0;
  //   this.pageNo = 1;
  //   this.skip = 0;
  //   //this.filterValue = value;
  //   if (value.filters.length > 0) {
  //     if (this.filterValue.filters.length) {

  //       //contact state
  //       let iscontactStateAvailableinKendo = value.filters.findIndex((x: any) => x.field == "ProspectContact.Office1State")!

  //       if (iscontactStateAvailableinKendo != -1) {

  //         let iscontactStateAvailable = this.filterValue.filters.findIndex((x: any) => x.field == "ProspectContact.Office1State")

  //         if (iscontactStateAvailable == -1) {
  //           //state find in filter when filter apply
  //           let stateFilter = {
  //             field: "ProspectContact.Office1State",
  //             operator: "contains",
  //             value: this.selectedContactStateFilterValue
  //           }
  //           this.filterValue.filters.push(stateFilter)
  //         } else {
  //           this.filterValue.filters.splice(iscontactStateAvailable, 1)
  //           let stateFilter = {
  //             field: "ProspectContact.Office1State",
  //             operator: "contains",
  //             value: this.selectedContactStateFilterValue
  //           }
  //           this.filterValue.filters.push(stateFilter)
  //           this.filterValue = value;
  //         }
  //       }
  //       else {
  //         this.filterValueArraycontactState = []
  //       }
  //       //check condition for state
  //       let isLobAvailableinKendo = value.filters.findIndex((x: any) => x.field == "WBALine.Line")!

  //       if (isLobAvailableinKendo != -1) {

  //         let isLobAvailable = this.filterValue.filters.findIndex((x: any) => x.field == "WBALine.Line")

  //         if (isLobAvailable == -1) {
  //           //state find in filter when filter apply
  //           let stateFilter = {
  //             field: "WBALine.Line",
  //             operator: "contains",
  //             value: this.selectedLobFilterValue
  //           }
  //           this.filterValue.filters.push(stateFilter)
  //         } else {
  //           this.filterValue.filters.splice(isLobAvailable, 1)
  //           let stateFilter = {
  //             field: "WBALine.Line",
  //             operator: "contains",
  //             value: this.selectedLobFilterValue
  //           }
  //           this.filterValue.filters.push(stateFilter)
  //           this.filterValue = value;
  //         }
  //       }
  //       else {
  //         this.filterValueArrayLob = []
  //       }
  //       //all filter excpect state
  //       let isStateAvailableinKendo = value.filters.findIndex((x: any) => x.field == "State")
  //       if (isStateAvailableinKendo != -1) {

  //         let isStateAvailable = this.filterValue.filters.findIndex((x: any) => x.field == "State")

  //         if (isStateAvailable == -1) {
  //           //state find in filter when filter apply
  //           let stateFilter = {
  //             field: "State",
  //             operator: "contains",
  //             value: this.selectedStateFilterValue
  //           }
  //           this.filterValue.filters.push(stateFilter)
  //         } else {
  //           this.filterValue.filters.splice(isStateAvailable, 1)
  //           let stateFilter = {
  //             field: "State",
  //             operator: "contains",
  //             value: this.selectedStateFilterValue
  //           }
  //           this.filterValue.filters.push(stateFilter)
  //           this.filterValue = value;
  //           console.log("555", this.selectedStateFilterValue);
  //         }

  //       } else {
  //         console.log("666", this.selectedStateFilterValue);
  //         //remove state filter in kendo remove bind ngModel value
  //         this.filterValueArrayState = []

  //       }

  //       this.DateFormattingInAllGroups(value)
  //       // let abcd = this.filterValue
  //       // value.filters.map((obj: any) => {
  //       //   //finding fieldType in master array
  //       //   let fieldType = this.filters.find((x: any) => x.field == obj.field)?.editor;
  //       //   if (fieldType?.toLowerCase() == 'date') obj.value = this.datepipe.transform(obj.value, 'yyyy-MM-dd')
  //       // })
  //     } else {
  //       console.log("222", this.selectedStateFilterValue);
  //       this.filterValueArrayState = []
  //       this.filterValueArrayLob = [];
  //       this.filterValueArraycontactState = [];
  //     }
  //     this.getProspectList(this.selectedListId);
  //     console.log("333", this.selectedStateFilterValue);
  //     // else {
  //     //   this.masterProspectList.length = 0
  //     //   this.hideShowGrid = true
  //     // }
  //   } else {
  //     this.masterProspectList.length = 0
  //     this.hideShowGrid = false;
  //     this.invalidFilter = true;
  //   }
  //   this.isFilter1 = !val;
  // }

  //Navigate to 'view prospect' screen
  navigate(data: any) {
    sessionStorage.setItem('prospectId', data.id);
    this._router.navigateByUrl(
      '/' + this._navigationPath.getProspectsUrl() + '/' + this._navigationPath.getProspectsViewUrl()
    );
  }

  //Loading prospect List.
  getProspectList(listId: number, signalR = false) {
    let data;
    let __tempFilter: any = this.convertArrayToString(this.filterValueCopy);
    let _tempFilter: any = this.convertEqualsIntoContains(__tempFilter);;
    this.filterValueCopy = this.convertStringToBool(this.filterValueCopy);
    let temgridFilter: any = this.gridFilter;
    temgridFilter = this.DateFormattingInAllGroups(this.gridFilter);
    console.log("filter", this.gridFilter)

    if (listId == 0) {
      data = {
        "listId": 0,
        "pageNumber": this.pageNo,
        "pageSize": this.pageSize,
        "totalCount": signalR ? -1 : this.totalCount,
        "sortDir": this.sort[0].dir,
        "sortCol": this.sort[0].field,
        "searchText": this.searchGrid,
        "filterJson": _tempFilter.filters != null && _tempFilter.filters.length > 0 ? JSON.stringify(this.filterValueCopy) : '',
        "gridFilterJson": temgridFilter.filters != null && temgridFilter.filters.length > 0 ? JSON.stringify(temgridFilter) : '',
        "UserId": this.controlId
      }
    } else {
      data = {
        "listId": listId,
        "pageNumber": this.pageNo,
        "pageSize": this.pageSize,
        "totalCount": signalR ? -1 : 0,
        "sortDir": this.sort[0].dir,
        "sortCol": this.sort[0].field,
        "searchText": this.searchGrid,
        "filterJson": _tempFilter.filters != null && _tempFilter.filters.length > 0 ? JSON.stringify(this.filterValueCopy) : '',
        "gridFilterJson": temgridFilter.filters != null && temgridFilter.filters.length > 0 ? JSON.stringify(temgridFilter) : '',
        "UserId": this.controlId,
      }
    }

    if (listId.toString() == '0' && data.totalCount == 0) this.getProspectList(listId, true)
    this._ProspectListService.prospectPagination(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe((resp: any) => {
      if (resp) {
        this.filterBlock = true;
        this.gridFilter = this.convertStringIntoDate(this.gridFilter);
        if (!signalR) {
          this.masterProspectList = resp.items[0].prospectList;
        }
        // this.FinalProspectList = {
        //   data: this.masterProspectList,
        //   total: resp.totalRecord
        // };
        if (signalR) {
          this.FinalProspectList.total = resp.totalRecord;
          if (signalR && resp.totalRecord == 0) { this.FinalProspectList.data = []; this.ngUnsubscribe.next(); }
        }
        else {
          this.FinalProspectList.data = this.masterProspectList;
          this.FinalProspectList.total = resp.totalRecord > 0 ? resp.totalRecord : this.FinalProspectList.total;
        }

        if (this.actionPerform == 'ApplyFilter' && this.masterProspectList.length > 0) {
          this.hideShowGrid = false
          this.isDataAvailable = true;
          return;
        }
        if (this.actionPerform == 'ApplyFilter' && this.masterProspectList.length == 0) {
          this.searchGrid = "";
          this.nextButtonVisiable = false;
          this.isDataAvailable = false;
          this.hideShowGrid = true;
          this.filterMsg = "No Data Available";
          if (this.listTypeName == "Dynamic") {
            this.nextButtonVisiable = true;
            this.isDataAvailable = false;
            this.hideShowGrid = true;
          }
          return;
        }
        if (this.actionPerform == 'FilterChange') {
          this.hideShowGrid = false
        }
        if (this.masterProspectList.length > 0 && this.actionPerform == 'searchAll') {
          this.isDataAvailable = true;
          this.renderer.removeClass(this.filterDiv.nativeElement, 'show');
          return;
        }
        if (this.masterProspectList.length == 0 && this.actionPerform == 'searchAll') {
          this.renderer.addClass(this.filterDiv.nativeElement, 'show');
          return;
        }

        this.prospectList = resp.items[0].prospectList;
        this.totalRecord = resp.totalRecord;
        if (this.prospectList) {
          this._paginator.totalRecords = resp.totalRecord;
          // this.getProspectListByDisposition(this.prospectList);
        }
        this.errorList = [];
        this.showError = false;
      }
    },
      (err: any) => {
        this.filterBlock = true;
        this.gridFilter = this.convertStringIntoDate(this.gridFilter);
        this.errorList = [];
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));

            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {
            this.errorList = [];
            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      })
  }

  //searching on all columns
  public onFilter(): void {
    this.actionPerform = 'searchAll'
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
    this.getProspectList(this.selectedListId);
  }

  searchValue(inputValue: any) {
    this.searchGrid = inputValue;
  }

  //searching in grid columns
  public filterChange(filter: CompositeFilterDescriptor): void {
    // filter.filters.map((obj: any) => {
    //   if (obj.field == 'prospectInformation.workersCompensationExpirationDate')
    //     obj.value = this.datepipe.transform(obj.value, 'yyyy-MM-dd')
    //   if (obj.field == 'prospectInformation.packageExpirationDate')
    //     obj.value = this.datepipe.transform(obj.value, 'yyyy-MM-dd')
    //   if (obj.field == 'finalDispositionDate')
    //     obj.value = this.datepipe.transform(obj.value, 'yyyy-MM-dd')
    // })

    // filter.filters.map((obj: any) => {
    //   //finding fieldType in master array
    //   let fieldType = this.filters.find((x: any) => x.field.toLowerCase() == obj.field.toLowerCase())?.editor;
    //   if (fieldType?.toLowerCase() == 'date') obj.value = this.datepipe.transform(obj.value, 'yyyy-MM-dd');
    //   // let isExist = this.gridFilter.filters.some((x: any) => x.field.toLowerCase() == obj.field.toLowerCase());
    //   // if (!isExist) {
    //   //   this.gridFilter.filters.push(obj);
    //   // }
    // })
    this.actionPerform = "FilterChange"
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
    this.gridFilter = { ...filter };

    if (this.gridFilter.filters.length > 0) {
      // Loop through each filter to check and clean phone numbers
      this.gridFilter.filters.forEach((f: any) => {
        f.filters.forEach((element: any) => {
          if (element.field === 'phoneNumber') {
            element.value = this.cleanPhoneNumber(element.value);
          }
        });

      });
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "state")
      if (stateIndex > -1 && this.gridFilterArrayState.length == 0)
        this.gridFilter.filters.splice(stateIndex, 1)
      if (stateIndex < 0) this.gridFilterArrayState = []


      var clientStatusIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "prospectInformation.status")
      if (clientStatusIndex > -1 && this.gridFilterArrayClientStatus.length == 0)
        this.gridFilter.filters.splice(clientStatusIndex, 1)
      if (clientStatusIndex < 0) this.gridFilterArrayClientStatus = []

      var LastContactedByIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "lastContactedByDisplayName")
      if (LastContactedByIndex > -1 && this.gridLastContactedByListArray.length == 0)
        this.gridFilter.filters.splice(LastContactedByIndex, 1)
      if (LastContactedByIndex < 0) this.gridLastContactedByListArray = [];

    }

    if (!this.gridFilter?.filters.length) {
      this.gridFilterArrayState = [];
      this.gridLastContactedByListArray = [];
      this.gridFilterArrayClientStatus = [];
    }

    if (this.gridFilterArrayState != undefined && this.gridFilterArrayState.length > 0)
      this.onChange(this.gridFilterArrayState);

    if (this.gridFilterArrayClientStatus != undefined && this.gridFilterArrayClientStatus.length > 0)
      this.onChangeForClientStatus(this.gridFilterArrayClientStatus);

    if (this.gridLastContactedByListArray != undefined && this.gridLastContactedByListArray.length > 0)
      this.LastContactedByChange(this.gridLastContactedByListArray);
    //this.hideShowGrid = false
    this.$searchingText.next();

  }

  private cleanPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/[\(\)\-\s]/g, '');
  }

  closeError() {
    this.showError = false;
  }

  //clear mutlselect dropdown filter value
  public clearValueMultiselect() {

    this.gridFilterArrayClientStatus = [];
    this.gridFilterArrayState = [];

  }

  cancel() {
    if (this.saveType == 'share-prospect') {
      this._confirmBox.message = this._userMessages.showWarningPopupMessage;
    }
    if (this.saveType == '') {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
    }
    this.saveType = ''
    this._confirmBox.isVisible = true;
  }

  sendData(type: any) {
    this.isSubmitted = true;
    this.saveType = type;
    if (this.filterValue.filters.length == 0) {
      this.invalidFilter = true
      return
    }
    if (!this.createQueryForm.valid) {
      return
    }
    if (this.f.listName.value.trim().length === 0) {
      this.whitespace = true
      return
    }
    if (this.f.listType.value == 'Static' && sessionStorage.getItem('editMode') != 'edit') {
      //show popup
      this._confirmBoxForExceedList.message = 'Creating a static list of' + ' ' + this.FinalProspectList.total + ' ' + 'prospects?';
      this._confirmBoxForExceedList.isVisible = true;
      return;
    }
    //for edit mode
    if (this.showListChangeWarning) {
      // this.cancel()
      this.saveDataApiCall();
    } else {
      //for create mode
      this.saveDataApiCall();
    }
  }

  saveDataApiCall() {
    let __tempFilter: any = this.convertArrayToString(this.filterValueCopy);
    let _tempFilter: any = this.convertEqualsIntoContains(__tempFilter);;
    this.filterValueCopy = this.convertStringToBool(this.filterValueCopy);
    this.isSubmitted = true
    // if (this.FinalProspectList.total > 20000 && this.f.listType.value == 'Static') {
    //   //show popup
    //   this._confirmBoxForExceedList.message = this._userMessages.openPopListMessage;
    //   this._confirmBoxForExceedList.isVisible = true;
    //   return
    // }
    if (this.filterValue.filters.length == 0) {
      this.invalidFilter = true
      return
    }
    if (!this.createQueryForm.valid) {
      return
    }
    let listType = 'Static'
    if (this.f.listType.value == 'Dynamic' || this.f.listType.value == 'DYNAMIC' || this.f.listType.value == 'System-Default' || this.f.listType.value == 'Template') {
      listType = 'Query'
    }

    // let listStatus ='Template'
    // if(this.f.listType.value == 'Marketing-List'){
    //   listStatus ='Marketing-List'
    // }
    let saveAs = 'Draft'
    if (this.f.listType.value == 'System-Default') {
      saveAs = 'System-Default'
    }
    if (this.f.listType.value == 'Template') {
      saveAs = 'Template'
    }

    let data = {
      "listFilter": {
        listId: this.ListId,
        ListType: listType,
        TemplateId: this.templateId,
        SaveAs: saveAs,
        //ListStaus:listStatus,
        listEntityType: 'Prospect',
        expirationDate: this.datepipe.transform(this.f.expirationDate.value, 'yyyy-MM-dd')!,
        listName: this.f.listName.value,
        filterJson: JSON.stringify(this.filterValueCopy),
        prospectId: this.filteredDataProspectsIds,
        totalCount: this.FinalProspectList.total
      }
    };

    this.errorList = []

    this._saveJsonService.saveCreateList(data).subscribe((resp: any) => {
      if (this.errorList.length == 0) {
        this.showError = false;
        this._listDataService.setListId(resp)
        sessionStorage.setItem('listId', resp);
        //again setting Query to Dynamic for list type display
        if (data.listFilter.ListType == 'Query') {
          data.listFilter.ListType = 'Dynamic'
        }
        this._listDataService.setListDetailedData(data.listFilter)

        sessionStorage.setItem('manageListId', resp);

        this.createQueryForm.controls['listName'].patchValue('');
        this.filterValue = { logic: 'and', filters: [] };
        this.createQueryForm.controls.listName?.setValidators(null);
        this.createQueryForm.controls.listName?.updateValueAndValidity();
        if (this.f.listType.value == 'Static') {
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'share-prospects');
        }
        else if (this.f.listType.value == 'Dynamic')
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'share-prospect');
        else {
          sessionStorage.removeItem('manageListId');
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
        }
      }
    },
      (err: any) => {
        if (err.status !== 200) {
          this.showError = true;
          if (err.status == 403 && this.unAthorizesd) {
            this.errorList.push(this.unAthorizesd);
            return;
          }
          if (err.status === 400) {
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorMessage = "Error : " + arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
  }

  prospectPaginate(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNo = (event.take + event.skip) / event.take;
    this.totalCount = this.FinalProspectList.total
    //calling api
    this.getProspectList(this.selectedListId);
  }

  getProspectListByDisposition(values: any) {
    this.data1 = [];
    this.data2 = [];
    values.forEach((x: any) => {
      if (x.finalDispo === 'Yes') {
        this.data1.push(x);
      }
      else if (x.finalDispo === 'No') {
        this.data2.push(x);
      }
    });
    this.data1.forEach((x: any) => {
      this.data1.sort(function (a: any, b: any) {
        let prop1 = a["totalDisposition"];
        let prop2 = b["totalDisposition"];
        return ((prop1 < prop2) ? 1 : ((prop1 > prop2) ? -1 :
          (a.prospectName.toLowerCase() < b.prospectName.toLowerCase() ? -1 :
            (a.prospectName.toLowerCase() > b.prospectName.toLowerCase() ? 1 : 0))))
      })
    });
    this.data2.forEach((x: any) => {
      this.data2.sort(function (a: any, b: any) {
        let prop1 = a["totalDisposition"];
        let prop2 = b["totalDisposition"];
        return ((prop1 < prop2) ? 1 : ((prop1 > prop2) ? -1 :
          (a.prospectName.toLowerCase() < b.prospectName.toLowerCase() ? -1 :
            (a.prospectName.toLowerCase() > b.prospectName.toLowerCase() ? 1 : 0))))
      })
    });
    this.data1 = this.data1.concat(this.data2);
    this.FinalProspectList.data = this.data1;
  }

  //method to activeate the prospect
  activeProspect(isActive: boolean, pId: any) {
    let saveData = {
      "id": pId,
      "isActive": !isActive,
    }
    this._AddprospectService
      .deactivateProspect(saveData.id, saveData).subscribe((resp: any) => {
        if (resp) {
          this.getProspectList(0);
        }
      })
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = this.FinalProspectList.total
    this.sort = sort;
    this.getProspectList(this.selectedListId);
  }

  //To hide column filters
  columnFilter(value: boolean) {
    this.isFilter = !value;
  }

  confirmBoxCancel() {
    //calling save api
    //this.saveDataApiCall()
    this._confirmBox.isVisible = false;
    this.activeModal.close();
    this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
  }

  confirmBoxEditPopup() {
    //calling save api
    this.saveDataApiCall()
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });

  }

  //code added
  //multiselect ngmodel
  getMultiselectValue(currentItem: any) {
    typeof currentItem.value == 'string' && currentItem.value != ""
      ? (currentItem.value = currentItem.value.split(','))
      : currentItem.value;
    return currentItem.value;
  }

  //multiselect for industry
  getMultiselectValueByid(currentItem: any) {
    typeof currentItem.value == 'string' && currentItem.value != ""
      ? (currentItem.value = currentItem.value.split(',').map(Number))
      : currentItem.value;
    return currentItem.value;
  }

  //numerical ngmodel
  getNumericValue(currentItem: any) {
    if (currentItem.value) {
      return +currentItem.value;
    } else {
      return currentItem.value;
    }
  }

  getNumericValueEXP(currentItem: any) {

    if (currentItem.value) {
      //let expValue = this.ValidateKey.formatDecimalNumber(currentItem.value.toString());
      let _curValue = currentItem.value.toString();
      let index0 = _curValue.charAt(0)
      let index1 = '.'
      let index2 = _curValue.charAt(_curValue.includes('.') ? 2 : 1)
      let index3 = _curValue.charAt(_curValue.includes('.') ? 3 : 2)
      let expValue = index0 + index1 + index2 + index3;
      return (+expValue);
    } else {
      return currentItem.value;
    }
  }

  //convert decimal to int
  public onChangeForNumber(
    event: any,
    currentItem: FilterDescriptor,
  ) {
    currentItem.value = event.toFixed();
  }

  //multiselect select dropdown
  public onChangeForMultiselect(
    event: any,
    currentItem: FilterDescriptor,
  ) {
    currentItem.value = event;
  }

  //covert array to string
  convertArrayToString(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertArrayToString(x);
        } else {
          x.value = x?.value?.toString();
        }
      });
    }
    return json;
  }
  //convert string to bool
  convertStringToBool(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertStringToBool(x);
        } else {
          x.value = (x.value === 'true') ? true : (x.value === 'false') ? false : x.value;
        }
      });
    }
    return json;
  }


  //convert eq operator to contains operator
  convertEqualsIntoContains(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertEqualsIntoContains(x)
        }
        else {
          if (x.operator == 'eq') {
            if (x.field === 'State' || x.field === 'WBA.MasterBusinessStatus' || x.field === 'WBAStatusId'
              || x.field === 'ProspectRecall.Status' || x.field === 'prospectstatus.Status' || x.field === 'MasterPolicyType.line'
              || x.field === 'Contact.Office1State' || x.field === 'WBALine.Line' || x.field === 'ProspectIndustry.IndustryName'
              || x.field === 'PI.IndustryName' || x.field == 'ProspectProgress.ProgressName' || x.field === 'ProspectEpicStatus.EpicStatusName' || x.field === 'ProfitCenter'
              || x.field == 'wba.OriginalProducerName' || x.field == "Prospect.ModifiedBy" || x.field == "WBA.MarketingManagerId" || x.field == "Prospect.LastContactedBy" || x.field === "WBA.OwnerUserName" || x.field == 'WBA.CreatedBy' || x.field == 'Prospect.CreatedBy') {
              x.operator = 'contains';
            }
          } else if
            (x.operator == 'neq') {
            if (x.field === 'industryclassandcodeinformation.Class.NAICS' || x.field === 'industryclassandcodeinformation.Class.SIC'
              || x.field === 'industryclassandcodeinformation.Class.PCS' || x.field === 'industryclassandcodeinformation.Class.NTEE') {
              x.operator = 'doesnotcontain'
            }
          }
        }
      })
    }
    return json;
  }

  //convert contains operator to eq operator
  convertContainsIntoEquals(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertContainsIntoEquals(x)
        }
        else {
          if (x.operator == 'contains') {
            if (x.field === 'State' || x.field === 'WBA.MasterBusinessStatus' || x.field === 'WBAStatusId'
              || x.field === 'ProspectRecall.Status' || x.field === 'prospectstatus.Status' || x.field === 'MasterPolicyType.line'
              || x.field === 'Contact.Office1State' || x.field === 'WBALine.Line' || x.field === 'ProspectIndustry.IndustryName'
              || x.field === 'PI.IndustryName' || x.field == 'ProspectProgress.ProgressName' || x.field === 'ProspectEpicStatus.EpicStatusName' || x.field === 'ProfitCenter'
              || x.field == 'wba.OriginalProducerName' || x.field == "Prospect.ModifiedBy" || x.field == "WBA.MarketingManagerId" || x.field == "Prospect.LastContactedBy" || x.field === "WBA.OwnerUserName" || x.field == 'WBA.CreatedBy' || x.field == 'Prospect.CreatedBy') {
              x.operator = 'eq';
            }
          } else if (x.operator == 'doesnotcontain') {
            if (x.field === 'industryclassandcodeinformation.Class.NAICS' || x.field === 'industryclassandcodeinformation.Class.SIC'
              || x.field === 'industryclassandcodeinformation.Class.PCS' || x.field === 'industryclassandcodeinformation.Class.NTEE') {
              x.operator = 'neq'
            }
          }
        }
      })
    }
    return json;
  }
  convertStringIntoDate(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertStringIntoDate(x)
        }
        else {
          let fieldType = this.filters.find((f: any) => f.field.toLowerCase() === x.field.toLowerCase())?.editor;
          if (fieldType) {
            if (fieldType?.toLowerCase() == 'date') {
              // x.value = new Date(x.value)
              let formattedDate: any = this.datepipe.transform(x.value, 'MMMM d, y');
              x.value = new Date(formattedDate);
            }
          }
        }
      })
    }
    return json;
  }
  convertFormattedDateIntoDate(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertFormattedDateIntoDate(x)
        }
        else {
          let fieldType = this.filters.find((f: any) => f.field.toLowerCase() === x.field.toLowerCase());
          if (fieldType?.editor == 'date') {
            let formattedDate: any = this.datepipe.transform(x.value, 'MMMM d, y');
            x.value = new Date(formattedDate);
          }
        }
      })
    }
    return json;
  }

  getUserList() {
    let data = {
      "lookUpType": 'AllUser',
      "rowCount": 500
    }
    this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((resp: any) => {
      this.userList = resp.userList;
    })
  }

  //multi select for client status
  public onChangeForClientStatus(event: any) {
    this.actionPerform = "FilterChange";
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "prospectInformation.status")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "prospectInformation.status",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "prospectInformation.status",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = 0;
    this.$searchingText.next();
  }

  public LastContactedByChange(event: any): void {
    this.actionPerform = "FilterChange";
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "lastContactedByDisplayName")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "lastContactedByDisplayName",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "lastContactedByDisplayName",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = 0;
    this.$searchingText.next()
  }
  // getSelectedColumns(listId: number) {
  //   this._saveJsonService.getSelectedColumns(listId).subscribe((resp: any) => {
  //     if (resp) {
  //       // get checked/unchecked from columnList
  //       this.excludedColumns = resp.columnList
  //       this.saveSelectedColumns();
  //     }
  //   },
  //     (error: any) => {

  //     })
  // }
  // saveSelectedColumns() {
  //   let data = {
  //     "listId": this.ListID,
  //     "columnList": this.excludedColumns
  //   }
  //   let finishedValues = data.columnList.filter((n: any) => n.isVisible).length;
  //   if (finishedValues > 0)
  //     this._saveJsonService.saveSelectedColumns(data).subscribe((resp: any) => {
  //       if (resp) {

  //         this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'share-prospects');

  //       }
  //     },
  //       (err: any) => {
  //         if (err.status !== 200) {
  //           if (err.status === 400) {
  //             this.showError = true;
  //             let obj = err.error.errors;
  //             var arr = Object.keys(obj).map((key) => ({
  //               type: key,
  //               value: obj[key],
  //             }));
  //             this.errorMessage = "Error : " + arr[0].value[0];
  //             this.errorList.push(this.errorMessage);
  //           } else {

  //             this.showError = true;
  //             this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
  //             this.errorList.push(this.errorMessage);
  //           }
  //         }
  //       });
  // }
}



