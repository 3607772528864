<div class="main-body-con">
    <div class="page-container pt-4">
        <div class="page-hdr-outer ps-4 pe-4">
            <form novalidate [formGroup]="createContactForm">
                <div class="hd-list">
                    <div class="page-hdr">
                        <h2>{{pageTitle}} - Contact</h2>
                        <!-- <a href="javascript:void(0)" [routerLink]="['../manage-list']" title="close"
                            class="closebtn"><img src="assets/images/close.svg" alt="" /></a> -->
                        <div class="es-link">
                            <a href="javascript:void(0)" [routerLink]="['../manage-list']"
                                class="btn btn-outline-primary"><span><img src="assets/images/back.svg" alt=""
                                        class="plus-icon" /></span>Back to
                                List</a>
                        </div>
                    </div>
                    <div class="search-panel mb-3">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <div class="flex-div align-items-start">
                                    <div class="lsttyp">
                                        <label class="mb-2 crh">List Type<span class="star-color"> *</span></label>
                                        <div class="mt-2 list-type">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="listType"
                                                    (change)="onlistTypeChange('Static')" formControlName="listType"
                                                    id="inlineRadio1" value="Static" checked />
                                                <label class="form-check-label" for="inlineRadio1">Static</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="listType"
                                                    (change)="onlistTypeChange('Dynamic')" formControlName="listType"
                                                    id="inlineRadio2" value="Dynamic" />
                                                <label class="form-check-label" for="inlineRadio2">Dynamic</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="listType"
                                                    (change)="onlistTypeChange('System-Default')"
                                                    formControlName="listType" id="inlineRadio3"
                                                    value="System-Default" />
                                                <label class="form-check-label"
                                                    for="inlineRadio3">System-Default</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="listType"
                                                    (change)="onlistTypeChange('Template')" formControlName="listType"
                                                    id="inlineRadio4" value="Template" />
                                                <label class="form-check-label" for="inlineRadio4">Template</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="listType"
                                                    (change)="onlistTypeChange('isMarketing')"
                                                    formControlName="listType" id="inlineRadio5" value="isMarketing" />
                                                <label class="form-check-label"
                                                    for="inlineRadio5">Marketing-List</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mx-4 listCtrl">
                                        <label class="mb-2 crh">List Name<span class="star-color"> *</span></label>
                                        <input (input)="checkBlankSpace($event)" class="form-control"
                                            formControlName="listName" placeholder="Enter the List Name"
                                            (focusout)="removeSpaces($event,f.listName)" />
                                        <div class="display-error" *ngIf="whitespace">
                                            <span>
                                                {{ _createListScreen.listName.pattern }}
                                            </span>

                                        </div>
                                        <div class="display-error" *ngIf="f.listName.invalid && isSubmitted">
                                            <div>
                                                <span *ngIf="f.listName.errors?.required">
                                                    {{ _createListScreen.listName.required }}
                                                </span>
                                                <span *ngIf="f.listName.errors?.maxlength">
                                                    {{ _createListScreen.listName.maxlength }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="listCtrl">
                                        <label class="mb-2" [ngClass]="{
                                            isDisabled: disableExpirationCalendar
                                          }">
                                            Expiration Date
                                        </label>

                                        <div class="date-control">
                                            <p-calendar [disabled]="disableExpirationCalendar" class="form-control"
                                                [readonlyInput]="true" [showIcon]="true" [iconDisplay]="'input'" [minDate]="minimumDate"
                                                placeholder="MM/DD/YYYY" formControlName="expirationDate"
                                                dateFormat="mm/dd/yy">
                                            </p-calendar>
                                        </div>
                                    </div>
                                    <div class="listCtrl mx-4">
                                        <input class="form-check-input mx-0 chkpos" type="checkbox"
                                            (change)="onTemplateCheck($event)">
                                        <label class="mb-2 ms-2">Create list using Template</label>

                                        <div class="mg-left">
                                            <select class="select-cs form-select" formControlName="template"
                                                (change)="onTemplateChange($event)">
                                                <option value="">Select</option>
                                                <option *ngFor="let temp of templateList" [ngValue]="temp.listId">
                                                    {{temp.listName}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="showError">
                        <div class="col-12">
                            <div class="alert alert-danger alert-dismissible fade show my-2" role="alert">
                                <ul class="view-error">
                                    <li *ngFor="let err of errorList">{{ err }}</li>
                                </ul>
                                <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                                    aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="grid-con1 ">
                        <div class="grid__wrapper wrp-pros  grid-custom-height pt-0 m-0 mx-0">

                            <div class="accordion mb-3" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                            aria-expanded="false">
                                            <span class="filter-button me-3 mx-0" [ngClass]="{ actbtn: isFilter1 }">
                                                <img src="assets/images/filter.svg" alt="" class="flt-icon" /></span>
                                            Filter
                                            Contacts
                                        </button>
                                    </h2>

                                    <div #filterDiv id="collapseThree" class="accordion-collapse collapse show"
                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">

                                        <div class="accordion-body" *ngIf="filterBlock">
                                            <div class="display-error mb-2" *ngIf="invalidFilter == true">
                                                <span *ngIf="invalidFilter == true">
                                                    {{ _createListScreen.filterName.required }}
                                                </span>
                                            </div>
                                            <kendo-filter #filter [filters]="filters" [value]="filterValue">
                                            </kendo-filter>

                                            <div class="apply-filter-btn">
                                                <button kendoButton (click)="applyFilter(filter.value,isFilter1)">
                                                    Apply filter
                                                </button>
                                            </div>

                                        </div>
                                        <ng-template let-currentItem #templateForState>
                                            <kendo-multiselect [checkboxes]="true" [data]="statesList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true" textField="stateCode"
                                                valueField="stateCode" [ngModel]="getMultiselectValue(currentItem)"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #template2>
                                            <kendo-multiselect [checkboxes]="true" [data]="businessLine"
                                                [ngModel]="getMultiselectValue(currentItem)"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true" textField="businessLineName"
                                                valueField="businessLineName" [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForContact>
                                            <kendo-multiselect [checkboxes]="true" [data]="statesList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true" textField="stateCode"
                                                valueField="stateCode" [ngModel]="getMultiselectValue(currentItem)"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForPolicy>
                                            <kendo-multiselect [checkboxes]="true" [data]="policyTypeList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true" textField="businessLineName"
                                                valueField="businessLineName"
                                                [ngModel]="getMultiselectValue(currentItem)"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForProgress>
                                            <kendo-multiselect [checkboxes]="true" [data]="progress"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)" textField="progressName"
                                                valueField="progressName" [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateprofitCenter>
                                            <kendo-multiselect [checkboxes]="true" [data]="profitCenterData"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [ngModel]="getMultiselectValue(currentItem)"
                                                [valuePrimitive]="true" textField="stateCode" valueField="stateCode"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForClientStatus>
                                            <kendo-multiselect [checkboxes]="true" [data]="clientStatus"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true" textField="stateCode"
                                                valueField="stateCode" [ngModelOptions]="{standalone: true}"
                                                [ngModel]="getMultiselectValue(currentItem)">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForRecallStatus>
                                            <kendo-multiselect [checkboxes]="true" [data]="recallForStatusList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForWbaStatus>
                                            <kendo-multiselect [checkboxes]="true" [data]="wbaForStatusList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForWbaBussinessStatus>
                                            <kendo-multiselect [checkboxes]="true" [data]="businessStatusList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)"
                                                textField="businessStatusName" valueField="businessStatusName"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForPrimaryIndustry>
                                            <kendo-multiselect [checkboxes]="true" [data]="prospectIndustryList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValueByid(currentItem)"
                                                textField="prospectIndustryName" valueField="id"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForSecondaryIndustry>
                                            <kendo-multiselect [checkboxes]="true" [data]="prospectIndustryList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValueByid(currentItem)"
                                                textField="prospectIndustryName" valueField="id"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForResult>
                                            <kendo-multiselect [checkboxes]="true" [data]="resultListForGrid"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)" textField="epicStatusName"
                                                valueField="epicStatusName" [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForNumber>
                                            <kendo-numerictextbox [style.width.px]="170" format="n0"
                                                (valueChange)="onChangeForNumber($event,currentItem)"
                                                [value]="getNumericValue(currentItem)"></kendo-numerictextbox>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForNumberEXP>
                                            <kendo-numerictextbox [style.width.px]="170" format="n2"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [value]="getNumericValueEXP(currentItem)"></kendo-numerictextbox>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForNumberWithoutComma>
                                            <kendo-numerictextbox [style.width.px]="170" format="00"
                                                (valueChange)="onChangeForNumber($event,currentItem)"
                                                [value]="getNumericValue(currentItem)"></kendo-numerictextbox>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForUserName>
                                            <kendo-multiselect [checkboxes]="true" [data]="userList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)" textField="display_name"
                                                valueField="display_name" [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>

                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="this.masterContactList.length == 0 && hideShowGrid" class="mid-con mt-3">
                                {{filterMsg}}
                            </div>
                            <div class="mid-con mt-3" *ngIf="isDataAvailable">

                                <div class="list-content">
                                    <div class="list-md-con pt-0">

                                        <div class="row">
                                            <div class="grid-con1">
                                                <div class="grid__wrapper wrp-pros grid-custom-height pt-0 m-0 mx-0 "
                                                    id="noaction">
                                                    <kendo-grid filterable="menu" [resizable]="true" [virtualColumns]="true"
                                                        (filterChange)="filterChange($event)" [data]="FinalContactList"
                                                        [skip]="skip" [filter]="gridFilter"
                                                        [pageable]="{ pageSizes: true, position: 'top' }"
                                                        [pageSize]="pageSize" (pageChange)="contactPaginate($event)"
                                                        [sortable]="{allowUnsort: false,mode: multiple ? 'multiple' : 'single'}"
                                                        [sort]="sort" (sortChange)="sortChange($event)">
                                                        <ng-template kendoPagerTemplate let-totalPages="totalPages"
                                                            let-currentPage="currentPage">
                                                            <div class="flex-div justify-content-between grid-hd">
                                                                <!-- <div class="flex-div">
                                                                    <kendo-textbox
                                                                        placeholder="Search in all columns..."
                                                                        #searchText
                                                                        (change)="searchValue(searchText.value)"
                                                                        [style.width.px]="205">
                                                                    </kendo-textbox><img src="assets/images/search.svg"
                                                                        (click)="onFilter()" alt="" class="ms-2" />
                                                                </div> -->
                                                                <div class="flex-div">
                                                                    <div class="flex-div">
                                                                        <kendo-pager-prev-buttons>
                                                                        </kendo-pager-prev-buttons>
                                                                        <kendo-pager-numeric-buttons
                                                                            [buttonCount]="buttonCount">
                                                                        </kendo-pager-numeric-buttons>
                                                                        <kendo-pager-next-buttons>
                                                                        </kendo-pager-next-buttons>
                                                                        <kendo-pager-page-sizes [pageSizes]="sizes">
                                                                        </kendo-pager-page-sizes>
                                                                        <kendo-grid-messages pagerItems="Items"
                                                                            [pagerItemsPerPage]="'Items per page'">
                                                                        </kendo-grid-messages>
                                                                    </div>
                                                                    <kendo-pager-info class="bd-l">
                                                                    </kendo-pager-info>
                                                                </div>
                                                            </div>
                                                            <!-- <button [ngClass]="{ actbtn: isFilter }"
                                                                (click)="columnFilter(isFilter)" class="filter-button">
                                                                <img src="assets/images/filter.svg" alt=""
                                                                    class="flt-icon" />
                                                            </button> -->
                                                        </ng-template>
                                                        <!-- <kendo-grid-column field="fullName" title="Name"
                                                            [minResizableWidth]="450" [maxResizableWidth]="500"
                                                            [width]="400">
                                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                                <span href="javascript:void(0)" title=""
                                                                    pTooltip="{{ dataItem.fullName }}"
                                                                    tooltipPosition="bottom"
                                                                    tooltipStyleClass="tooltipstyle">{{
                                                                    dataItem.fullName
                                                                    }}</span>
                                                            </ng-template>
                                                        </kendo-grid-column> -->
                                                        <kendo-grid-column [minResizableWidth]="150"
                                                            [maxResizableWidth]="500" field="firstName"
                                                            title="First Name" [width]="250">
                                                            <ng-template kendoGridFilterCellTemplate let-dataItem
                                                                let-filter let-column="column">
                                                                <kendo-grid-string-filter-cell [filter]="filter"
                                                                    [column]="column" operator="startswith">
                                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                                        <a href="javascript:void(0)" title=""
                                                                            pTooltip="{{ dataItem.firstName }}"
                                                                            tooltipPosition="bottom"
                                                                            tooltipStyleClass="tooltipstyle">{{
                                                                            dataItem.firstName
                                                                            }}</a>
                                                                    </ng-template>
                                                                </kendo-grid-string-filter-cell>
                                                            </ng-template>
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column [minResizableWidth]="150"
                                                            [maxResizableWidth]="500" field="lastName" title="Last Name"
                                                            [width]="250">
                                                            <ng-template kendoGridFilterCellTemplate let-dataItem
                                                                let-filter let-column="column">
                                                                <kendo-grid-string-filter-cell [filter]="filter"
                                                                    [column]="column" operator="startswith">
                                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                                        <a href="javascript:void(0)" title=""
                                                                            pTooltip="{{ dataItem.lastName }}"
                                                                            tooltipPosition="bottom"
                                                                            tooltipStyleClass="tooltipstyle">{{
                                                                            dataItem.lastName
                                                                            }}</a>
                                                                    </ng-template>
                                                                </kendo-grid-string-filter-cell>
                                                            </ng-template>
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="prospectName" title="Prospect"
                                                            [minResizableWidth]="500" [maxResizableWidth]="700"
                                                            [width]="530">
                                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                                <span href="javascript:void(0)" title=""
                                                                    pTooltip="{{ dataItem.prospectName }}"
                                                                    tooltipPosition="bottom"
                                                                    tooltipStyleClass="tooltipstyle">{{
                                                                    dataItem.prospectName }}</span>
                                                            </ng-template>
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="carrierName"
                                                            title="Carrier/ Wholesaler" [minResizableWidth]="500"
                                                            [maxResizableWidth]="700" [width]="430">
                                                            <ng-template kendoGridFilterCellTemplate let-dataItem
                                                                let-filter let-column="column">
                                                                <kendo-grid-string-filter-cell [filter]="filter"
                                                                    [column]="column" operator="startswith">
                                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                                        <span href="javascript:void(0)" title=""
                                                                            pTooltip="{{ dataItem.carrierName }}"
                                                                            tooltipPosition="bottom"
                                                                            tooltipStyleClass="tooltipstyle">{{
                                                                            dataItem.carrierName }}</span>
                                                                    </ng-template>
                                                                </kendo-grid-string-filter-cell>
                                                            </ng-template>
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="office1PhoneNumber"
                                                            [minResizableWidth]="130" [maxResizableWidth]="500"
                                                            title="Contact Phone" [width]="150">
                                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                                <span href="javascript:void(0)">
                                                                    {{
                                                                    dataItem.office1PhoneNumber
                                                                    ? (dataItem.office1PhoneNumber | mask:
                                                                    "(000) 000-0000")
                                                                    : " "
                                                                    }}
                                                                </span>
                                                            </ng-template>
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>


                                                        </kendo-grid-column>
                                                        <!-- <kendo-grid-column field="contactType" [minResizableWidth]="110"
                                                            [maxResizableWidth]="500" title="Contact Type"
                                                            [width]="120">
                                                            <ng-template kendoGridFilterCellTemplate let-dataItem
                                                                let-filter let-column="column">
                                                                <kendo-grid-string-filter-cell [filter]="filter"
                                                                    [column]="column" operator="startswith">
                                                                </kendo-grid-string-filter-cell>
                                                            </ng-template>
                                                        </kendo-grid-column> -->
                                                        <kendo-grid-column field="isPrimary" [minResizableWidth]="110"
                                                            [maxResizableWidth]="500" title="Primary" [width]="120"
                                                            filter="boolean">
                                                            <kendo-grid-messages filterPrimary="Primary"
                                                                filterNonPrimary="Non-Primary">
                                                            </kendo-grid-messages>
                                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                                {{
                                                                dataItem.isPrimary === true
                                                                ? "Primary"
                                                                : dataItem.isPrimary === false
                                                                ? "Non-Primary"
                                                                : dataItem.isPrimary
                                                                }}
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <!-- <kendo-grid-column field="office1Email" title="Email" [width]="50">
              </kendo-grid-column> -->
                                                        <kendo-grid-column field="jobTitle" [minResizableWidth]="200"
                                                            [maxResizableWidth]="500" title="Job Title" [width]="220">
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <!-- <kendo-grid-column field="office1AddressLine1"
                                                            [minResizableWidth]="280" [maxResizableWidth]="500"
                                                            title="Address" [width]="300">
                                                            <ng-template kendoGridFilterCellTemplate let-dataItem
                                                                let-filter let-column="column">
                                                                <kendo-grid-string-filter-cell [filter]="filter"
                                                                    [column]="column" operator="startswith">
                                                                </kendo-grid-string-filter-cell>
                                                            </ng-template>
                                                        </kendo-grid-column> -->
                                                        <kendo-grid-column field="office1City" [minResizableWidth]="150"
                                                            [maxResizableWidth]="500" title="City" [width]="180">
                                                            <ng-template kendoGridFilterCellTemplate let-dataItem
                                                                let-filter let-column="column">
                                                                <kendo-grid-string-filter-cell [filter]="filter"
                                                                    [column]="column" operator="startswith">
                                                                </kendo-grid-string-filter-cell>
                                                            </ng-template>
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>
                                                        </kendo-grid-column>

                                                        <!-- <kendo-grid-column field="office1State" title="State"
                                                            [width]="50">
                                                        </kendo-grid-column> -->
                                                        <kendo-grid-column field="office1State" [minResizableWidth]="70"
                                                            [maxResizableWidth]="500" title="State" [width]="90">
                                                            <!-- <ng-template kendoGridFilterMenuTemplate>
                                                                <kendo-multiselect [checkboxes]="true"
                                                                    [autoClose]="false" [data]="statesList"
                                                                    [valuePrimitive]="true"
                                                                    [(ngModel)]="gridFilterArrayState"
                                                                    [ngModel]="getMultiselectValueChange(gridFilterArrayState)"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (valueChange)="onChange($event)"
                                                                    textField="stateCode" valueField="stateCode">
                                                                </kendo-multiselect>
                                                            </ng-template> -->
                                                            <ng-template kendoGridFilterMenuTemplate let-column="column"
                                                                let-filter="filter" let-filterService="filterService">
                                                                <multicheck-filter [data]="statesList"
                                                                    textField="stateCode" valueField="stateCode"
                                                                    [currentFilter]="filter"
                                                                    [filterService]="filterService"
                                                                    [isPrimitive]="false"
                                                                    [field]="column.field"></multicheck-filter>
                                                            </ng-template>

                                                        </kendo-grid-column>

                                                        <!-- <kendo-grid-column [minResizableWidth]="80"
                                                            [maxResizableWidth]="500" [width]="80" field="state"
                                                            title="State">
                                                            <ng-template kendoGridFilterMenuTemplate>
                                                                <kendo-multiselect [checkboxes]="true"
                                                                    [(ngModel)]="gridFilterArrayState"
                                                                    [autoClose]="false" [data]="statesList"
                                                                    [valuePrimitive]="true"
                                                                    (valueChange)="onChange($event)"
                                                                    textField="stateCode" valueField="stateCode">
                                                                </kendo-multiselect>
                                                            </ng-template>
                                                        </kendo-grid-column> -->


                                                        <!-- <kendo-grid-column field="office1ZipCode"
                                                            [minResizableWidth]="110" [maxResizableWidth]="500"
                                                            title="ZIP Code" [width]="130">
                                                            <ng-template kendoGridFilterCellTemplate let-dataItem
                                                                let-filter let-column="column">
                                                                <kendo-grid-string-filter-cell [filter]="filter"
                                                                    [column]="column" operator="startswith">
                                                                </kendo-grid-string-filter-cell>
                                                            </ng-template>
                                                        </kendo-grid-column> -->
                                                        <kendo-grid-column [minResizableWidth]="150"
                                                            [maxResizableWidth]="500" field="office1Extension"
                                                            title="Extension" [width]="100">
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column [minResizableWidth]="150"
                                                            [maxResizableWidth]="500" field="home1PhoneNumber"
                                                            title="Secondary Phone No" [width]="150">


                                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                                {{dataItem.home1PhoneNumber ?
                                                                (dataItem.home1PhoneNumber | phone) : ""}}
                                                            </ng-template>


                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column [minResizableWidth]="150"
                                                            [maxResizableWidth]="500" field="office1Email" title="Email"
                                                            [width]="200">
                                                            <ng-template kendoGridFilterCellTemplate let-dataItem
                                                                let-filter let-column="column">
                                                                <kendo-grid-string-filter-cell [filter]="filter"
                                                                    [column]="column" operator="startswith">
                                                                </kendo-grid-string-filter-cell>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column [minResizableWidth]="150"
                                                            [maxResizableWidth]="500" field="createdDate"
                                                            title="Created On" [width]="170" filter="date"
                                                            format="{0:d}">
                                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                                {{
                                                                dataItem.createdDate
                                                                | date: "MM/dd/yyyy"
                                                                }}
                                                            </ng-template>
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <date-range-filter [field]="column.field"
                                                                    [filter]="filter" [filterService]="filterService">
                                                                </date-range-filter>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                    </kendo-grid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="btn-blk" *ngIf="isDataAvailable || nextButtonVisiable">
                                <div *ngIf="!showPublishButton">
                                    <button *ngIf="!showSaveButton || nextButtonVisiable" class="btn btn-primary"
                                        (click)="sendData('contact-share-user-list')">Next</button>
                                    <button *ngIf="showSaveButton" class="btn btn-primary"
                                        (click)="saveDataApiCall()">Save</button>
                                </div>
                                <button *ngIf="showPublishButton" class="btn btn-primary"
                                    (click)="openPopUpForPublish()">Save</button>
                            </div>
                            <!-- Dialog for confirmation -->
                            <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible1"
                                [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false"
                                [resizable]="false" [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
                                <p>{{ _confirmBox.message1 }}</p>
                                <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                                    <p-button (click)="_confirmBox.isVisible1 = false" label="Cancel"
                                        styleClass="btn btn-outline-primary">
                                    </p-button>
                                    <p-button (click)="savePublishList()" label="OK"
                                        styleClass="btn btn-primary"></p-button>
                                </ng-template>
                            </p-dialog>
                        </div>
                    </div>
                </div>

            </form>
        </div>

        <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
            [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
            [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
            <p>{{ _confirmBox.message }}</p>
            <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                <ng-container>
                    <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary">
                    </p-button>
                    <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
                </ng-container>
                <ng-container>
                    <p-button label="OK" styleClass="btn btn-primary"></p-button>
                    <p-button (click)="_confirmBox.isVisible = false" label="Cancel"
                        styleClass="btn btn-outline-primary">
                    </p-button>
                </ng-container>
            </ng-template>
        </p-dialog>
        <p-dialog [header]="_confirmBoxForExceedList.header2" [modal]="true"
            [(visible)]="_confirmBoxForExceedList.isVisible" [breakpoints]="{ '960px': '75vw' }"
            [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
            [styleClass]="_confirmBoxForExceedList.borderColor" *ngIf="_confirmBoxForExceedList">
            <p>{{ _confirmBoxForExceedList.message }}</p>
            <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                <ng-container>
                    <p-button (click)="_confirmBoxForExceedList.isVisible = false" label="OK"
                        styleClass="btn btn-primary"></p-button>
                </ng-container>
            </ng-template>
        </p-dialog>
    </div>
</div>
