<div class="main-body-con">
  <div class="page-container pt-4">
    <div class="page-hdr-outer ps-4 pe-4">
      <form novalidate [formGroup]="createQueryForm">
        <div class="hd-list">
          <div class="page-hdr">
            <h2>{{pageTitle}} - Prospect</h2>
            <div class="es-link">
              <a href="javascript:void(0)" [routerLink]="['../manage-list']" class="btn btn-outline-primary"><span><img
                    src="assets/images/back.svg" alt="" class="plus-icon" /></span>Back to
                List</a>
            </div>
          </div>
          <div class="search-panel mb-3">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12">
                <div class="flex-div align-items-start">
                  <div>
                    <label class="mb-2 crh">List Type<span class="star-color"> *</span></label>
                    <div class="mt-2 list-type">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="listType"
                          (change)="onlistTypeChange('Static')" formControlName="listType" id="inlineRadio1"
                          value="Static" checked />
                        <label class="form-check-label" for="inlineRadio1">Static</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="listType"
                          (change)="onlistTypeChange('Dynamic')" formControlName="listType" id="inlineRadio2"
                          value="Dynamic" />
                        <label class="form-check-label" for="inlineRadio2">Dynamic</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="listType"
                          (change)="onlistTypeChange('System-Default')" formControlName="listType" id="inlineRadio3"
                          value="System-Default" />
                        <label class="form-check-label" for="inlineRadio3">System-Default</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="listType"
                          (change)="onlistTypeChange('Template')" formControlName="listType" id="inlineRadio4"
                          value="Template" />
                        <label class="form-check-label" for="inlineRadio4">Template</label>
                      </div>
                    </div>
                  </div>
                  <div class="mx-4 listCtrl">
                    <label class="mb-2 crh">List Name<span class="star-color"> *</span></label>
                    <input (input)="checkBlankSpace($event)" class="form-control" formControlName="listName"
                      placeholder="Enter the List Name" (focusout)="removeSpaces($event,f.listName)" />
                    <div class="display-error" *ngIf="whitespace">
                      <span>
                        {{ _createListScreen.listName.pattern }}
                      </span>

                    </div>
                    <div class="display-error" *ngIf="f.listName.invalid && isSubmitted">
                      <div>
                        <span *ngIf="f.listName.errors?.required">
                          {{ _createListScreen.listName.required }}
                        </span>
                        <span *ngIf="f.listName.errors?.maxlength">
                          {{ _createListScreen.listName.maxlength }}
                        </span>

                      </div>
                    </div>
                  </div>
                  <div class="listCtrl">
                    <label class="mb-2" [ngClass]="{
                        isDisabled: disableExpirationCalendar
                      }">
                      Expiration Date
                    </label>

                    <div class="date-control">
                      <p-calendar [disabled]="disableExpirationCalendar" class="form-control" [readonlyInput]="true"
                        [showIcon]="true" [iconDisplay]="'input'" [minDate]="minimumDate" placeholder="MM/DD/YYYY"
                        formControlName="expirationDate" dateFormat="mm/dd/yy">
                      </p-calendar>
                    </div>
                  </div>


                  <div class="listCtrl mx-4">
                    <input class="form-check-input mx-0 chkpos" type="checkbox" (change)="onTemplateCheck($event)">
                    <label class="mb-2 ms-2">Create list using Template</label>

                    <div class="mg-left">
                      <select class="select-cs form-select" formControlName="template"
                        (change)="onTemplateChange($event)">
                        <option value="">Select</option>
                        <option *ngFor="let temp of templateList" [ngValue]="temp.listId">{{temp.listName}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="showError">
            <div class="col-12">
              <div class="alert alert-danger alert-dismissible fade show my-2" role="alert">
                <ul class="view-error">
                  <li *ngFor="let err of errorList">{{ err }}</li>
                </ul>
                <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                  aria-label="Close"></button>
              </div>
            </div>
          </div>

        </div>
      </form>
      <div class="row">
        <div class="grid-con1">
          <div class="grid__wrapper wrp-pros grid-custom-height  pt-0 m-0 mx-0">

            <div class="accordion mb-3" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <!-- id="filterlist" -->
                  <button class="accordion-button collapsed" type="button" data-bs-target="#collapseThree"
                    data-bs-toggle="collapse" aria-expanded="false">
                    <span class="filter-button me-3 mx-0" [ngClass]="{ actbtn: isFilter1 }"> <img
                        src="assets/images/filter.svg" alt="" class="flt-icon" /></span> Filter Prospects
                  </button>
                </h2>
                <div #filterDiv id="collapseThree" class="accordion-collapse collapse show"
                  aria-labelledby="headingThree" data-bs-parent="#accordionExample">

                  <div class="accordion-body" *ngIf="filterBlock">
                    <div class="display-error mb-2" *ngIf="invalidFilter == true">
                      <span *ngIf="invalidFilter == true">
                        {{ _createListScreen.filterName.required }}
                      </span>
                    </div>
                    <kendo-filter #filter [filters]="filters" [value]="filterValue"
                      (valueChange)="onFilterChange($event)">
                    </kendo-filter>

                    <div class="apply-filter-btn">
                      <button kendoButton (click)="applyFilter(filter.value,isFilter1)">
                        Apply filter
                      </button>
                    </div>
                  </div>
                  <ng-template let-currentItem #templateForState>
                    <kendo-multiselect [checkboxes]="true" [data]="statesList"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" textField="stateCode" valueField="stateCode"
                      [ngModel]="getMultiselectValue(currentItem)" [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #template2>
                    <kendo-multiselect [checkboxes]="true" [data]="businessLine"
                      [ngModel]="getMultiselectValue(currentItem)"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" textField="businessLineName" valueField="businessLineName"
                      [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForContact>
                    <kendo-multiselect [checkboxes]="true" [data]="statesList"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" textField="stateCode" valueField="stateCode"
                      [ngModel]="getMultiselectValue(currentItem)" [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForPolicy>
                    <kendo-multiselect [checkboxes]="true" [data]="policyTypeList"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" textField="businessLineName" valueField="businessLineName"
                      [ngModel]="getMultiselectValue(currentItem)" [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForProgress>
                    <kendo-multiselect [checkboxes]="true" [data]="progress"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" [ngModel]="getMultiselectValue(currentItem)" textField="progressName"
                      valueField="progressName" [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateprofitCenter>
                    <kendo-multiselect [checkboxes]="true" [data]="profitCenterData"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [ngModel]="getMultiselectValue(currentItem)" [valuePrimitive]="true" textField="stateCode"
                      [ngModelOptions]="{standalone: true}" valueField="stateCode">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForClientStatus>
                    <kendo-multiselect [checkboxes]="true" [data]="clientStatus"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" textField="stateCode" valueField="stateCode"
                      [ngModelOptions]="{standalone: true}" [ngModel]="getMultiselectValue(currentItem)">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForRecallStatus>
                    <kendo-multiselect [checkboxes]="true" [data]="recallForStatusList"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" [ngModel]="getMultiselectValue(currentItem)"
                      [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForWbaStatus>
                    <kendo-multiselect [checkboxes]="true" [data]="wbaForStatusList"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" [ngModel]="getMultiselectValue(currentItem)"
                      [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForWbaBussinessStatus>
                    <kendo-multiselect [checkboxes]="true" [data]="businessStatusList"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" [ngModel]="getMultiselectValue(currentItem)"
                      textField="businessStatusName" valueField="businessStatusName"
                      [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForPrimaryIndustry>
                    <kendo-multiselect [checkboxes]="true" [data]="prospectIndustryList"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" [ngModel]="getMultiselectValueByid(currentItem)"
                      textField="prospectIndustryName" valueField="id" [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForSecondaryIndustry>
                    <kendo-multiselect [checkboxes]="true" [data]="prospectIndustryList"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" [ngModel]="getMultiselectValueByid(currentItem)"
                      textField="prospectIndustryName" valueField="id" [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForResult>
                    <kendo-multiselect [checkboxes]="true" [data]="resultListForGrid"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" [ngModel]="getMultiselectValue(currentItem)" textField="epicStatusName"
                      valueField="epicStatusName" [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForNumber>
                    <kendo-numerictextbox [style.width.px]="170" format="n0"
                      (valueChange)="onChangeForNumber($event,currentItem)"
                      [value]="getNumericValue(currentItem)"></kendo-numerictextbox>
                  </ng-template>
                  <ng-template let-currentItem #templateForNumberEXP>
                    <kendo-numerictextbox [style.width.px]="170" format="n2"
                      (valueChange)="onChangeForMultiselect($event,currentItem)"
                      [value]="getNumericValueEXP(currentItem)"></kendo-numerictextbox>
                  </ng-template>
                  <ng-template let-currentItem #templateForUserName>
                    <kendo-multiselect [checkboxes]="true" [data]="userList"
                      (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                      [valuePrimitive]="true" [ngModel]="getMultiselectValue(currentItem)" textField="display_name"
                      valueField="display_name">
                    </kendo-multiselect>
                  </ng-template>
                  <ng-template let-currentItem #templateForNumberWithoutComma>
                    <kendo-numerictextbox [style.width.px]="170" format="00"
                      (valueChange)="onChangeForNumber($event,currentItem)"
                      [value]="getNumericValue(currentItem)"></kendo-numerictextbox>
                  </ng-template>
                </div>
              </div>
            </div>
            <!-- <ng-template let-currentItem #templateForState>
                <kendo-multiselect [checkboxes]="true" [data]="statesList" [(ngModel)]="filterValueArrayState"
                  [ngModelOptions]="{standalone: true}" (valueChange)="onChangeForState($event)" [autoClose]="false"
                  [valuePrimitive]="true" textField="stateCode" valueField="stateCode">
                </kendo-multiselect>
              </ng-template>

              <ng-template let-currentItem #template2>
                <kendo-multiselect [checkboxes]="true" [data]="filterForLob" [(ngModel)]="filterValueArrayLob"
                  [ngModelOptions]="{standalone: true}" (valueChange)="onChangeForLob($event)" [autoClose]="false"
                  [valuePrimitive]="true">
                </kendo-multiselect>
              </ng-template>
              <ng-template let-currentItem #templateForContact>
                <kendo-multiselect [checkboxes]="true" [data]="statesList"
                  (valueChange)="onChangeForContactState($event)" [autoClose]="false" [valuePrimitive]="true"
                  textField="stateCode" valueField="stateCode" [(ngModel)]="filterValueArraycontactState"
                  [ngModelOptions]="{standalone: true}">
                </kendo-multiselect>
              </ng-template> -->
            <div *ngIf="this.masterProspectList.length == 0 && hideShowGrid" class="mid-con mt-3"> {{filterMsg}}
            </div>

            <div *ngIf="isDataAvailable" class="mid-con mt-3">
              <div class="list-content">

                <div class="list-md-con pt-0">

                  <div class="row">
                    <div class="grid-con1 ">
                      <div class="grid__wrapper wrp-pros grid-custom-height pt-0 m-0 mx-0" id="noaction">
                        <kendo-grid filterable="menu" (filterChange)="filterChange($event)" [data]="FinalProspectList" [virtualColumns]="true"
                          [skip]="skip" [filter]="gridFilter" [sortable]="true" [resizable]="true" [sort]="sort"
                          (sortChange)="sortChange($event)" [selectable]="{
                        enabled: true,
                        checkboxOnly: true,
                        mode: 'multiple'
                      }" [pageable]="{ pageSizes: true, position: 'top' }" [pageSize]="pageSize" [sortable]="{
                        allowUnsort: false,
                        mode: multiple ? 'multiple' : 'single'
                      }" [sort]="sort" (pageChange)="prospectPaginate($event)">
                          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                            <div class="flex-div justify-content-between grid-hd">
                              <div class="flex-div">
                                <kendo-textbox placeholder="Search Company Name" #searchText
                                  (input)="searchValue(searchText.value)" [style.width.px]="205"></kendo-textbox><img
                                  src="assets/images/search.svg" (click)="onFilter()" alt="" class="ms-2" />
                              </div>
                              <div class="flex-div">
                                <div class="flex-div">
                                  <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                                  <kendo-pager-numeric-buttons [buttonCount]="buttonCount">
                                  </kendo-pager-numeric-buttons>
                                  <kendo-pager-next-buttons></kendo-pager-next-buttons>
                                  <!-- <kendo-pager-input></kendo-pager-input> -->

                                  <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
                                  <kendo-grid-messages pagerItems="Items" [pagerItemsPerPage]="'Items per page'">
                                  </kendo-grid-messages>
                                </div>
                                <kendo-pager-info class="bd-l"></kendo-pager-info>
                              </div>
                            </div>
                            <!-- <button [ngClass]="{ actbtn: isFilter }" (click)="columnFilter(isFilter)"
                              class="filter-button">
                              <img src="assets/images/filter.svg" alt="" class="flt-icon" />
                            </button> -->
                          </ng-template>

                          <kendo-grid-column [minResizableWidth]="500" [maxResizableWidth]="800" field="prospectName"
                            title="Company Name" [width]="530">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              <div class="cr-wd">
                                <span href="javascript:void(0)" title="" pTooltip="{{ dataItem.prospectName }}"
                                  tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">{{
                                  dataItem.prospectName
                                  }}</span>
                              </div>
                            </ng-template>
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column [minResizableWidth]="470" [maxResizableWidth]="800" field="addressLine1"
                            title="Addr Line 1" [width]="500">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{dataItem.addressLine1 | titlecase}}
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="addressLine2" [minResizableWidth]="470" [maxResizableWidth]="800"
                            title="Addr Line 2" [width]="500">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{dataItem.addressLine2 | titlecase}}
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="city" [minResizableWidth]="130" [maxResizableWidth]="500"
                            title="City" [width]="160">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{dataItem.city | titlecase}}
                            </ng-template>
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="state" [minResizableWidth]="60" [maxResizableWidth]="500"
                            title="State" [width]="80">
                            <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                              <kendo-multiselect [checkboxes]="true" [autoClose]="false" [data]="statesList"
                                [(ngModel)]="gridFilterArrayState" [valuePrimitive]="true"
                                (valueChange)="onChange($event)" textField="stateCode" valueField="stateCode">
                              </kendo-multiselect>
                            </ng-template> -->
                            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                              let-filterService="filterService">
                              <multicheck-filter [data]="statesList" textField="stateCode" valueField="stateCode"
                                [currentFilter]="filter" [filterService]="filterService" [isPrimitive]="false"
                                [field]="column.field"></multicheck-filter>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="zipCode" [minResizableWidth]="100" [maxResizableWidth]="500"
                            title="ZIP" [width]="120">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="prospectInformation.nonProfit" [minResizableWidth]="110"
                            [maxResizableWidth]="500" [width]="130" title="Non-Profit" filter="boolean">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{
                              dataItem.prospectInformation.nonProfit === true
                              ? "Yes"
                              : dataItem.prospectInformation.nonProfit === false
                              ? "No"
                              : dataItem.prospectInformation.nonProfit
                              }}
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="phoneNumber" [minResizableWidth]="120" [maxResizableWidth]="500"
                            title="Phone" [width]="130">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              <span href="javascript:void(0)">
                                {{ dataItem.phoneNumber | phone }}
                              </span>
                            </ng-template>
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="phoneExtension" [minResizableWidth]="80" [maxResizableWidth]="500"
                            title="Ext" [width]="120">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <number-range-filter [field]="column.field" [filter]="filter"
                                [filterService]="filterService" [format]="'n0'">
                              </number-range-filter>
                            </ng-template>
                          </kendo-grid-column>

                          <kendo-grid-column field="Website" [minResizableWidth]="550" [maxResizableWidth]="800"
                            title="Website" [width]="580">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="prospectInformation.noOfEmployees" [minResizableWidth]="105"
                            [maxResizableWidth]="500" title="Employee Size" [width]="120" filter="numeric"
                            class="text-right">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-numeric-filter-menu class="numeric-fld" [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="gte">
                              </kendo-grid-numeric-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="prospectInformation.yearsinBusiness" [minResizableWidth]="120"
                            [maxResizableWidth]="500" title="Years in Business" [width]="130" filter="numeric"
                            class="text-right">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="prospectInformation.industryName" [minResizableWidth]="550"
                            [maxResizableWidth]="800" title="Industry" [width]="580">
                            <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                              <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridFilterArrayIndustry"
                                [autoClose]="false" [data]="prospectIndustryList" [valuePrimitive]="true"
                                (valueChange)="onChangeForIndustry($event)" textField="prospectIndustryName"
                                valueField="id">
                              </kendo-multiselect>
                            </ng-template> -->
                            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                              let-filterService="filterService">
                              <multicheck-filter [data]="prospectIndustryList" textField="prospectIndustryName"
                                valueField="id" [currentFilter]="filter" [filterService]="filterService"
                                [isPrimitive]="false" [field]="column.field"></multicheck-filter>
                            </ng-template>

                          </kendo-grid-column>

                          <kendo-grid-column field="prospectInformation.ein" title="EIN" [minResizableWidth]="100"
                            [maxResizableWidth]="500" [width]="130">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="broker.brokerName" [minResizableWidth]="320"
                            [maxResizableWidth]="500" title="Broker" [width]="350">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="prospectInformation.status" [minResizableWidth]="110"
                            [maxResizableWidth]="500" title="Client" [width]="120">
                            <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                              <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridFilterArrayClientStatus"
                                [autoClose]="false" [data]="clientStatus" [valuePrimitive]="true"
                                (valueChange)="onChangeForClientStatus($event)">
                              </kendo-multiselect>
                            </ng-template> -->
                            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                              let-filterService="filterService">
                              <multicheck-filter [data]="clientStatus" [currentFilter]=" filter"
                                [filterService]="filterService" [isPrimitive]="true"
                                [field]="column.field"></multicheck-filter>
                            </ng-template>

                            <!-- <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
                              </kendo-grid-messages>
                              <ng-template kendoGridCellTemplate let-dataItem>
                                {{
                                dataItem.status === true
                                ? "Yes"
                                : dataItem.status === false
                                ? "No"
                                : dataItem.status
                                }}
                              </ng-template> -->
                          </kendo-grid-column>



                          <kendo-grid-column field="prospectInformation.revenue" [minResizableWidth]="115"
                            [maxResizableWidth]="500" title="Budget/Revenue" [width]="130" filter="numeric"
                            class="text-right" format="{0:c0}">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-numeric-filter-menu class="numeric-fld" [column]="column" [filter]="filter"
                                [filterService]="filterService">
                              </kendo-grid-numeric-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="pdfLink" [minResizableWidth]="550" [maxResizableWidth]="800"
                            title="PDF Link" [width]="580">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              <a href="{{dataItem.pdfLink}}" pTooltip="{{dataItem.pdfLink}}" tooltipPosition="bottom"
                                target="_blank">{{dataItem.pdfLink ? dataItem.pdfLink
                                :
                                ""}}</a>
                            </ng-template>
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="lastDialedOn" [minResizableWidth]="120" [maxResizableWidth]="500"
                            title="Last Dialed  Date" [width]="150" filter="date" format="{0:d}">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{
                              dataItem.lastDialedOn
                              | date: "MM/dd/yyyy"
                              }}
                            </ng-template>
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <date-range-filter [field]="column.field" [filter]="filter"
                                [filterService]="filterService">
                              </date-range-filter>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="prospectInformation.expmod" [minResizableWidth]="80"
                            [maxResizableWidth]="500" title="Exp Mod" [width]="100" filter="numeric" class="text-right">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-numeric-filter-menu [column]="column" [filter]="filter" class="numeric-fld"
                                [filterService]="filterService" operator="gte">
                              </kendo-grid-numeric-filter-menu>
                            </ng-template>
                            <!-- <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                              <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="gte">
                                <kendo-filter-eq-operator></kendo-filter-eq-operator>
                                <kendo-filter-neq-operator></kendo-filter-neq-operator>
                                <kendo-filter-gte-operator></kendo-filter-gte-operator>
                                <kendo-filter-gt-operator></kendo-filter-gt-operator>

                                <kendo-filter-lte-operator>
                                </kendo-filter-lte-operator>
                                <kendo-filter-lt-operator></kendo-filter-lt-operator>
                                <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
                              </kendo-grid-string-filter-cell>
                            </ng-template> -->
                            <!-- <ng-template kendoGridFilterMenuTemplate let-dataItem let-filter let-column>
                              <kendo-grid-string-filter-menu [column]="column" [filter]="filter" operator="startswith">
                              </kendo-grid-string-filter-menu>
                            </ng-template> -->
                          </kendo-grid-column>
                          <kendo-grid-column field="prospectInformation.expirationDate" [minResizableWidth]="120"
                            [maxResizableWidth]="500" title="Next X-Date" [width]="150" filter="date" format="{0:d}">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{
                              dataItem.prospectInformation.expirationDate
                              | date: "MM/dd/yyyy"
                              }}
                            </ng-template>
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <date-range-filter [field]="column.field" [filter]="filter"
                                [filterService]="filterService">
                              </date-range-filter>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column [minResizableWidth]="150" field="prospectInformation.packageExpirationDate"
                            title="PKG X-Date" [minResizableWidth]="150" [maxResizableWidth]="500" [width]="180"
                            filter="date" format="{0:d}">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{
                              dataItem.prospectInformation.packageExpirationDate
                              | date: "MM/dd/yyyy"
                              }}
                            </ng-template>
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <date-range-filter [field]="column.field" [filter]="filter"
                                [filterService]="filterService">
                              </date-range-filter>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column [minResizableWidth]="160"
                            field="prospectInformation.workersCompensationExpirationDate" title="WC X-Date"
                            [minResizableWidth]="120" [maxResizableWidth]="500" [width]="150" filter="date"
                            format="{0:d}">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{
                              dataItem.prospectInformation.workersCompensationExpirationDate
                              | date: "MM/dd/yyyy"
                              }}
                            </ng-template>
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <date-range-filter [field]="column.field" [filter]="filter"
                                [filterService]="filterService">
                              </date-range-filter>
                            </ng-template>
                          </kendo-grid-column>
                          <!-- [headerClass]="'headerCustomClass'" -->
                          <kendo-grid-column field="prospectInformation.packagePremium" [minResizableWidth]="80"
                            [maxResizableWidth]="500" title="Pkg Prem" [width]="100" filter="numeric" format="{0:c0}"
                            class="text-right">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-numeric-filter-menu [column]="column" [filter]="filter" class="numeric-fld"
                                [filterService]="filterService">
                              </kendo-grid-numeric-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <!-- <kendo-grid-column field="prospectInformation.workersCompensationExpirationDate"
                              title="WC X-Date" [width]="90" filter="date">
                              <ng-template kendoGridCellTemplate let-dataItem>
                                {{
                                dataItem.prospectInformation
                                .workersCompensationExpirationDate
                                | date: "MM/dd/yyyy"
                                }}
                              </ng-template>
                            </kendo-grid-column> -->
                          <kendo-grid-column field="prospectInformation.workersCompensationPremium"
                            [minResizableWidth]="100" [maxResizableWidth]="500" title="WC Prem" [width]="115"
                            filter="numeric" format="{0:c0}" class="text-right">
                            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                              let-filterService="filterService">
                              <kendo-grid-numeric-filter-menu [column]="column" [filter]="filter" class="numeric-fld"
                                [filterService]="filterService">
                              </kendo-grid-numeric-filter-menu>
                            </ng-template>
                          </kendo-grid-column>
                          <!-- <kendo-grid-column field="prospectInformation.packageExpirationDate" title="Pkg X-Date"
                              [width]="90" filter="date" format="{0:d}">
                              <ng-template kendoGridCellTemplate let-dataItem>
                                {{
                                dataItem.prospectInformation.packageExpirationDate
                                | date: "MM/dd/yyyy"
                                }}
                              </ng-template>
                            </kendo-grid-column> -->

                          <kendo-grid-column field="finalDispo" title="Final Disposition" [minResizableWidth]="120"
                            [maxResizableWidth]="500" [width]="150" filter="boolean">
                            <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
                            </kendo-grid-messages>
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{
                              dataItem.finalDispo === true
                              ? "Yes"
                              : dataItem.finalDispo === false
                              ? "No"
                              : dataItem.finalDispo
                              }}
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="lastContactedByDisplayName" [minResizableWidth]="150"
                            [maxResizableWidth]="500" title="Last Contacted By" [width]="180">
                            <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                              <kendo-multiselect [checkboxes]="true" [autoClose]="false" [data]="userList"
                                [valuePrimitive]="true" [(ngModel)]="gridLastContactedByListArray"
                                (valueChange)="LastContactedByChange($event)" textField="display_name"
                                valueField="display_name">
                              </kendo-multiselect>
                            </ng-template> -->
                            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                              let-filterService="filterService">
                              <multicheck-filter [data]="userList" [currentFilter]="filter"
                                [filterService]="filterService" [isPrimitive]="false" [field]="column.field"
                                textField="display_name" valueField="display_name"></multicheck-filter>
                            </ng-template>
                          </kendo-grid-column>
                        </kendo-grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-blk" *ngIf="isDataAvailable || nextButtonVisiable">
              <button style="visibility: hidden"></button>
              <button *ngIf="!showSaveButton || nextButtonVisiable" class="btn btn-primary"
                (click)="sendData('share-prospect')">Next</button>
              <button *ngIf="showSaveButton" class="btn btn-primary" (click)="saveDataApiCall()">Save</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
      [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
      [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
      <p>{{ _confirmBox.message }}</p>
      <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
        <ng-container *ngIf="!showListChangeWarning">
          <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary">
          </p-button>
          <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
        </ng-container>
        <ng-container *ngIf="showListChangeWarning">
          <p-button (click)="confirmBoxEditPopup()" label="OK" styleClass="btn btn-primary"></p-button>
          <p-button (click)="_confirmBox.isVisible = false" label="Cancel" styleClass="btn btn-outline-primary">
          </p-button>
        </ng-container>
      </ng-template>
    </p-dialog>
    <p-dialog [header]="_confirmBoxForExceedList.header" [modal]="true" [(visible)]="_confirmBoxForExceedList.isVisible"
      [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
      [styleClass]="_confirmBoxForExceedList.borderColor" *ngIf="_confirmBoxForExceedList">
      <p>{{ _confirmBoxForExceedList.message }}</p>
      <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
        <ng-container>
          <p-button (click)="saveDataApiCall()" label="OK" styleClass="btn btn-primary"></p-button>
          <p-button (click)="_confirmBoxForExceedList.isVisible = false" label="Cancel"
            styleClass="btn btn-primary"></p-button>
        </ng-container>
      </ng-template>
    </p-dialog>

  </div>
</div>
